import vert1 from "../vert-1.png";
import vert2 from "../vert-2.png";
import vert3 from "../vert-3.png";
import vert4 from "../vert-4.png";
import grid1 from "../grid-1.png";
import grid2 from "../grid-2.png";
import grid3 from "../grid-3.png";
import grid4 from "../grid-4.png";
import grid5 from "../grid-5.png";
import grid6 from "../grid-6.png";
import grid7 from "../grid-7.png";
import grid8 from "../grid-8.png";
import emch from "../emch.png";
import euro from "../euro.jpeg";
import pn from "../pn.png";
import itzone from "../itzone.png";
import ecommerce from "../ecommerce.png";
import education from "../education.png";
import hospital from "../hospital.png";
import realestate from "../realestate.png";
import restaurant from "../restaurant.png";

export const mn = {
  nav: {
    li1: "Үйлчилгээ",
    li2: "Үнэ",
    li3: "Боломж",
  },
  navFeatures: [
    {
      id: 0,
      type: "Блог",
      route: "https://blog.chatbot.mn/",
    },
    {
      id: 1,
      type: "Гарын авлага",
      route: "https://help.chatbot.mn/",
    },
    {
      id: 2,
      type: "Тусламж",
      route: "https://www.messenger.com/t/308105179819485",
    },

    // {
    //   id: 2,
    //   type: "Хөтөч",
    //   route: "/",
    // },
    {
      id: 3,
      type: "Онлайн сургалт",
      route: "/tutorials",
    },
    // {
    //   id: 4,
    //   type: "Видео хичээл",
    //   route: "/",
    // },
  ],
  hero: {
    h2: "Таны Бизнесийн Тоноглол",
    h1: "ХАРИЛЦАГЧАА ХЭРЭГЛЭГЧ БОЛГО",
    h3: "Хүн бүрт зориулсан виртуал туслах",
    companies:
      "Үйл ажиллагаандаа ЧАТБОТ үйлчилгээг нэвтрүүлэн амжилттай хэрэгжүүлж байгаа салбарын тэргүүлэгчид",
    bubble1: "Сайн байна уу. Chatbot.mn автомат чатбот хариулж байна.",
    bubble2: "Чатбот гэж юу вэ?",
    downloadLink:
      "https://chatbot.mn/uploads/home/Taniltsuulga%20Mongolia%20NEW%203.pdf",
  },
  vertTitle: "Чатбот маркетингийн чухал багаж",
  vertData: [
    {
      id: 0,
      title: "МАРКЕТИНГИЙН СУВГАА ХҮЧИРХЭГЖҮҮЛ",
      description:
        "Чатботыг маркетингийн идэвхжүүлэлтдээ ашиглан шинэ хэрэглэгчдийг татаж, тэдгээрийг халамжлан, цаашлаад танай компани болон брэндийн үнэнч хэрэглэгч болгох боломжтой.",
      img: vert1,
    },
    {
      id: 1,
      title: "ҮЙЛ АЖИЛЛАГААГАА САЙЖРУУЛЖ, ХӨГЖҮҮЛ",
      description:
        "Чатботоор дамжуулан хэрэглэгчийн датаг цуглуулах боломжтой бөгөөд цугларсан дата өгөгдлөө хүссэнээрээ шинжлэн цаашлаад түүний тусламжтай үйл ажиллагаагаа сайжруулах, хөгжүүлэх боломжтой.",
      img: vert4,
    },
    {
      id: 2,
      title: "ХЭРЭГЛЭГЧИЙН ҮЙЛЧИЛГЭЭГЭЭ ОНОВЧТОЙ БОЛГО",
      description:
        "Чатбот-оор дамжуулан хэрэглэгчдийн гомдол, саналыг хүлээн авч, тэдгээрт хэрэгцээтэй мэдээ мэдээллийг 24/7 цагийн турш тогтмол хүргэ.",
      img: vert3,
    },
    {
      id: 3,
      title: "БОРЛУУЛАЛТЫН ОРЛОГОО НЭМЭГДҮҮЛ",
      description:
        "ДЭЛГҮҮР цэсэд бүтээгдэхүүнээ байршуулан төлбөр тооцоо, барааны бүртгэл, үлдэгдэл, хүргэлт гэх мэт шаардлагатай бүх хэсгүүдийг ашиглаж, онлайнаар борлуулах боломжтой.",
      img: vert2,
    },
  ],
  integration: "ҮЙЛ АЖИЛЛАГААНДАА ТОГТМОЛ АШИГЛАДАГ ХЭРЭГСЛҮҮДТЭЙГЭЭ ХОЛБО.",
  integrationDesc:
    "Технологийн стекээ манай апп-уудтай холбосноор багийн үр ашгийг дээшлүүлж, үйлчлүүлэгчдийг аз жаргалтай болгож, чатбот ашиглан илүү ихийг хий.",
  subscribeM:
    "Та доорх хэсэгт имэйлээ бүртгүүлээд сонирхолтой мэдээ мэдээллийг тогтмол хүлээн авах боломжтой.",
  subscribeW:
    "Та доорх хэсэгт имэйлээ бүртгүүлээд харилцааны маркетинг болон технологийн салбарын чиглэл, чатбот хөгжүүлэлтийн боломжууд, шинэ бүтээгдэхүүн, үйлчилгээний тухай сонирхолтой мэдээ мэдээллийг тогтмол хүлээн авах боломжтой.",
  priceTitle:
    "Байгууллагын зорилго, үйл ажиллагаатайгаа уялдуулан дараах багцуудаас сонголтоо хийгээрэй.",
  price: [
    {
      id: 0,
      type: "PRO БАГЦ",
      price: "300,000₮",
      userCount: "Хэрэглэгчийн тоо",
      description:
        "Хувиараа бизнес эрхлэгч, жижиг дунд шатны компаниудад зориулсан хэрэглэгчидтэйгээ үр дүнтэй харилцах, харилцааг зохицуулах, хөгжүүлэхэд туслах багц.",
      button: "Бүртгүүлэх",
      link: "",
    },
    {
      id: 1,
      type: "ENTERPRISE БАГЦ",
      price: "900,000₮",
      userCount: "Хэрэглэгчийн тоо хязгааргүй",
      description:
        "Томоохон бизнес, Банк санхүү, Худалдаа үйлчилгээ, Үйлдвэрлэл аж ахуй нэгжүүдийн Хэрэглэгчийн үйлчилгээ, Борлуулалт, Маркетингийн багуудын бүтээмжийг дээшлүүлэхэд туслах багц.",
      button: "Мэдээлэл авах",
      link: "https://chatbot.mn/webform/dynamic/5535?__btn__=357219&psid=4090596247724602&__bid__=4",
    },
    {
      id: 2,
      type: "VIP",
      price: "Хязгааргүй хэрэглэгчид",
      userCount: "Хэрэглэгчийн тоо хязгааргүй",
      description:
        "Үйл ажиллагаандаа цахим шилжилт хийж буй томоохон компаниудад зориулсан,  кодчиллын мөр бүрийг хүссэн кодоороо өөрчлөн хөгжүүлэх боломжтой тусгай багц.",
      button: "Мэдээлэл авах",
      link: "https://chatbot.mn/webform/dynamic/5535?__btn__=357219&psid=4090596247724602&__bid__=4",
    },
  ],
  priceTitle2: "Ижил төстэй байгууллагын харьцуулалт",
  tableHd: [
    "БИДНИЙ БОЛОМЖ, ДАВУУ ТАЛУУД",
    "Chatbot.mn",
    "Manychat",
    "Chatfuel",
  ],
  tableDt: [
    ["Бот хөгжүүлэгчдийн багаар бот угсруулах", "+", "-", "-"],
    ["Зөвхөн танай байгууллагад зориулсан зөвлөх үйлчилгээ", "+", "-", "-"],
    ["Хариуцсан менежер", "+", "-", "-"],
    ["24/7 цагийн турш хариуцсан менежер ажиллах", "+", "-", "-"],
    ["Дэлгүүр 2.0 цэс", "+", "-", "-"],
    ["Хүргэлтийн үйлчилгээ", "+", "-", "-"],
    ["Онлайн гэрээ", "+", "-", "-"],
    ["Дан систем", "+", "-", "-"],
    ["Масс мессеж", "+", "+", "+"],
    ["Дотоодын төлбөрийн боломжууд", "+", "-", "-"],
    ["Гарын авлага, видео хичээлүүд", "+", "+", "+"],
    ["Бот хөгжүүлэгчдийн групп", "+", "+", "+"],
  ],
  priceCTA:
    "Та манай борлуулалтын менежертэй холбогдон салбар, компаний онцлог, үйл ажиллагаандаа тохирсон зөвлөгөөг аван өөрт шаардлагатай багцаа сонгоорой.",
  priceComment:
    "Үйл ажиллагаандаа ЧАТБОТ үйлчилгээг нэвтрүүлэн амжилттай хэрэгжүүлж байгаа салбарын тэргүүлэгчдийн сэтгэгдлүүдээс",
  priceCommentList: [
    {
      id: 0,
      img: emch,
      description:
        "Бидэнд хамгийн их таалагддаг чадвар болох Symptom checker буюу тухайн хүний шинж тэмдгүүдийг нэгтгэн дүгнээд ямар чиглэлийн эмчид хандах хэрэгтэй вэ гэдэг чиглүүлэг өгөх хэсэг болон эмнэлзүйн мэдээллүүдийг маш олон төрлөөр түгээх боломжтой байдаг нь чатбот үйлчилгээг зөвхөн автомат хариулагч биш гэдгийг харуулж байдаг.",
    },
    {
      id: 1,
      img: pn,
      description:
        "Хэрэглэгчид өдрийн аль ч цагт мэдээлэл асуудаг, хэрвээ бид хариулахгүй бол борлуулалт хийх магадлал буурах, хэрэглэгчид сэтгэл ханамжгүй болох, бизнесийн нэр хүнд унах гэх мэт сул талтай байдаг. Харин бид энэ асуудлыг чатботын тусламжтай шийдэж чаддаг.",
    },
    {
      id: 2,
      img: itzone,
      description:
        "Чатботын ард байдаг баг хамт олон маань байгууллага, хэрэглэгчдийн маань онцлогоос хамааран Технологи болон Дижитал орчин өдрөөс өдөрт хөгжиж байгаа энэ үед тэдгээрт тохирсон шаардлагатай сайжруулалт, хөгжүүлэлтүүдийг маш хурдтай хийж үйлчилдэг нь бидэнд давуу талтай байдаг.",
    },
    {
      id: 3,
      img: euro,
      description:
        "Зөвхөн мессенжер ч биш постын коммент тохиргоог идэвхжүүлэн хэрэглэгчдэдээ мэдээлэл өгч чаддаг, мөн онлайн аяллын бүртгэлээ хийх боломжтойгоос гадна хэзээ ч хаана ч хүссэн мэдээллийг хэрэглэгчдэд маань өгч чаддаг тул чатботыг бид манай 24/7 цагийн ажилтан гэж нэрлэх дуртай.",
    },
  ],
  faqHeader: "ТҮГЭЭМЭЛ АСУУЛТУУД",
  faq: [
    {
      id: 0,
      title: "Хэрхэн бот үүсгэх вэ?",
      description:
        "Та chatbot.mn рүүгээ анх нэвтэрч байгаа бол бүртгүүлээд, дахин хандаж байгаа бол нэвтрэх дараад орно. Нэг хэрэглэгч 5 бот үнэгүй үүсгэх боломжтой. 1 сарын турш PRO багцаар туршаад, цаашид ҮНЭГҮЙ багц руу автоматаар шилжинэ.",
    },
    {
      id: 1,
      title: "Ботоо яаж холбох вэ?",
      description:
        "Facebook page admin эрхтэй хүн чатботоо холбох салгах эрхтэй байдаг. Холбох товч дараад =>EDIT SETTINGS-рүү нэвтэрч=>пэйжээ сонгоод=>бүх тохиргоог YES болгоод DONE дарна.",
    },
    {
      id: 2,
      title: "Token expired болсон байна яах вэ?",
      description: `FACEBOOK ПЭЙЖНЫ АДМИНЫ ЭРХ, НУУЦ ҮГ, АДМИН СОЛИГДОХ ҮЕД token expired АЛДАА ЗААДАГ. Иймээс та \n1. Дашбоард руугаа нэвтрээд \n2. Тохиргоо цэс рүүгээ ороод \n3. Цэвэрлэх товч дараад \n4. Facebook холбох товч дараад \n5. Пэйжээ сонгоод холбоно`,
    },
    {
      id: 3,
      title: "Сунгалтаа хэрхэн хийх вэ? Сунгахгүй бол яах уу?",
      description:
        "Дашбоардны баруун дээд буланд байрлах багцан дээр дарахад ботоо сунгах болон ботын нэмэлт төлбөрт үйлчилгээнүүдийн сонголтууд гарах бөгөөд сонголтоо хийсний дараа нэхэмжлэх авах эсвэл QR-аар шууд төлбөрөө төлсөнөөр бот сунгагдах болно.",
    },
  ],
  productHeader: "НЭМЭЛТ FEATURES",
  mainFeature: {
    type: "Үндсэн үйлчилгээ",
    description:
      "Чатбот ХХК нь БОТ УГСРАЛТ, ХӨГЖҮҮЛЭЛТ хийх үйлчилгээ үзүүлдэг ба байгууллагын үйл ажиллагааны онцлогт тохируулан ДОТООД СИСТЕМҮҮДТЭЙ ХОЛБОЛТ хийж ашиглах боломжийг олгодог.",
  },
  subFeature: "Суурь боломжууд",
  subDesc: [
    "Мэдээллийн урсгалын дагуу меню өрүүлэх",
    "Түгээмэл асуулт хариултаа оруулах",
    "Бараа бүтээгдэхүүний мэдээлэл харуулах",
    "Хэрэглэгчийг хэд хэдэн бүлэг, сегментэд хуваах",
    "Hubspot холболт",
    "Зөвхөн тухайн ботыг хариуцсан менежер",
    "Аналитик харах Мониторинг хийх",
    "Төлбөр хураах",
    "Масс мессеж илгээх",
    "Санал асуулга авах",
    "Асуулгын форм бөглүүлэх",
    "Захиалгат зурвас илгээх гэх мэт олон боломжтой.",
  ],

  featureCTA:
    "Бид харилцагч, хэрэглэгчидтэйгээ байнгын эргэх холбоотой ойр байдаг.",
  featureCTA2: [
    {
      title: "Чадавхжуулах сургалт",
      description:
        "Харилцагчдын ажлын онцлог, боломжийг харгалзан сургалтын хуваарийг уян хатан болгосон. Ажлын өдрүүдийн 10:00 болон 14:00 цагаас сонгох боломжтой. <br/>/сургалт 1:30 минут үргэлжлэнэ/",
    },
    {
      title: "Харилцагчийн үйлчилгээ",
      description:
        "Чатботтой холбоотой асуух, сайжруулах, нэмэлт мэдээлэл оруулах, өөрчлөлт хийх үед бид заавар зөвөлгөө өгөхдөө таатай байдаг.",
    },
  ],
  productHeader2: "Давуу талууд",
  productGrid: [
    {
      id: 0,
      img: grid1,
      title: "Чадавхжуулах сургалт",
      description:
        "Зөвхөн тухайн хэрэглэгчид зориулан өгөхийг хүссэн мэдээ мэдээллүүдээ блок болон автомат хариултаар харилцан яриа өрнүүлэн хүргэх боломжтой.",
    },
    {
      id: 1,
      img: grid2,
      title: "Маркетинг, Борлуулалтын суваг",
      description:
        "Харилцааны маркетингийн хамгийн үр дүнтэй арга хэрэгслүүдийн нэг болох чатботоор дамжуулан хэрэглэгчдийг татах, цаашлан борлуулалт болгон хувиргах боломжтой.",
    },
    {
      id: 2,
      img: grid3,
      title: "Онлайн захиалгын систем",
      description:
        "Хэрэглэгчид бүтээгдэхүүн үйлчилгээний мэдээллийг ботоор дамжуулан авч, нэгээс дээш төрлийн барааг сагслан худалдан авч болохоос гадна цаг захиалга, ширээний захиалга гэх мэт үйлчилгээг авах боломжтой.",
    },
    {
      id: 3,
      img: grid4,
      title: "Мэдээллийн суваг сургалт",
      description:
        "Богино болон урт хугацааны урамшуулал, идэвхжүүлэлтийн мэдээ мэдээллийг масс мессеж хэлбэрээр чатботынхоо идэвхтэй хэрэглэгчдэдээ хүргэх боломжтой.",
    },
    {
      id: 4,
      img: grid5,
      title: "Хэрэглэгчийн сегментчилэл",
      description:
        "Хэрэглэгчдийг зан төлөв болон, сонирхсон бараа бүтээгдэхүүн, үйлдлээр нь хэд хэдэн ялгаатай бүлэг болгон ангилж, дараа дараагийн маркетингийн идэвхжүүлэлт, худалдан авалт, хямдрал урамшууллаа оновчтойгоор чиглүүлэх боломжтой.",
    },
    {
      id: 5,
      img: grid6,
      title: "Төлбөрийн шийдэл",
      description:
        "Дотоод болон гадаадын төлбөрийн системүүдтэй холболт хийгдсэн тул Та онлайнаар бараа бүтээгдэхүүнээ худалдаалаад зогсохгүй нийт захиалгын төлөвийн жагсаалтыг нэг цонхноос хянах боломжтой.",
    },
    {
      id: 6,
      img: grid7,
      title: "Тайлан болон дата шинжилгээ",
      description:
        "Чатбот-оор хандсан нийт хэрэглэгчдийн хандалтын тоог цаг хугацаатай нь харьцуулсан график, хүснэгтээр харах бөгөөд өнөөдрийн болон өмнөх хугацааны мэдээллүүдийг мөн тайлан хэлбэрээр харан шинжилгээ хийх боломжтой.",
    },
    {
      id: 7,
      img: grid8,
      title: "Хэрэглэгчийн санал, гомдол",
      description:
        "24/7 цагийн турш хэрэглэгчдийн санал, гомдлыг хүлээн авч, тэдгээрт хэрэгцээтэй, шаардлагатай мэдээллүүдийг цаг алдалгүй хүргэн, хэрэглэгчийн үйлчилгээний багийн үйл ажиллагааг дэмжих, бүтээгдэхүүн үйлчилгээнийхээ чанар, бүтээмжийг дээшлүүлэх боломжтой.",
    },
  ],
  button: {
    signin: "Нэвтрэх",
    signup: "Бүртгүүлэх",
    download: "Танилцуулга татах",
    free: "Туршиж үзэх",
    priceCTA: "Зөвлөгөө авах хүсэлт илгээх",
    dashboard: "Хянах самбар",
    logout: "Гарах",
  },
  termTitle: "Үйлчилгээний нөхцөл",
  termList: [
    {
      header: "Ерөнхий нөхцөл",
      desc: [
        {
          d: "Chatbot.mn харилцагчийн автомат хариулагч систем нь “Чатбот” ХХК-ний албан ёсны үйлчилгээ бөгөөд энэхүү үйлчилгээний нөхцлийн зорилго нь хэрэглэгч уг системийн үйлчилгээг ашиглахад үүсэх талуудын эрх үүрэг, харилцааг зохицуулахад оршино.",
          d1: [],
        },
        {
          d: "Хэрэглэгч нь системд бүртгэл буюу account үүсгэсэн цагаас эхлээд хаагдах хүртэл энэхүү үйлчилгээний нөхцөл нь хүчин төгөлдөр үйлчилнэ.",
          d1: [],
        },
      ],
    },
    {
      header: "Хэрэглэгчийн анхаарах зүйлс",
      desc: [
        {
          d: "Chatbot.mn систем нь Facebook Messenger, WeChat, Whatsapp, Telegram, Viber, Line зэрэг дэлхий нийтийн томоохон messenger платформуудтай холбогдон ажиллах боломжтой бөгөөд одоогийн байдлаар Facebook Messenger сувагтай холболт хийгдсэн.",
          d1: [],
        },
        {
          d: "Хэрэглэгч нь өөрийн үүсгэсэн ботын тусламжтай түгээж буй мэдээлэл, яриа зэрэг нь зөвхөн тухайн хэрэглэгч болон messenger хэрэглэгчийн хоорондын мэдээлэл бөгөөд үүнд “Чатбот” ХХК нь ямар нэг хуулийн хариуцлага хүлээхгүй.",
          d1: [],
        },
        {
          d: "Chatbot.mn систем дээр үүссэн хэрэглэгчийн account, ботын мэдээлэл болон messenger хэрэглэгчийн яриа зэрэг бүхий л мэдээллийн нууцлалыг “Чатбот” ХХК бүрэн хариуцах ба хуульд зааснаас бусад тохиолдолд гуравдагч этгээдэд задруулахгүй байх үүрэг хүлээнэ.",
          d1: [],
        },
        {
          d: "Хэрэглэгч нь өөрийн нэг account дээр нэг болон түүнээс дээш тооны бот үүсгэх боломжтой бөгөөд нэг ботод зөвхөн нэг Facebook хуудас холбох боломжтой.",
          d1: [],
        },
        {
          d: "Хэрэглэгч нь өөрийн үүсгэсэн ботыг зөвхөн өөрийн удирдах эрх бүхий admin эрхээр Facebook хуудастай холболт хийх боломжтой.",
          d1: [],
        },
        {
          d: "Системд үүсгэсэн ботоор дамжуулан хэрэглэгч нь messenger хэрэглэгчтэй харилцах явцад дараах хуулиар хориглогдсон үйл ажиллагаа явуулахыг хориглоно. Үүнд",
          d1: [
            "Залилан мэхлэх шинжтэй, гүтгэсэн, бусдын нэр хүндэд халдсан, доромжилсон, мөн оюуны өмчийн эрхийг зөрчсөн мэдээлэл оруулах, түгээх",
            "Аливаа байгууллага ба хувь хүний нууцад хамаарах мэдээллийг зохих зөвшөөрөлгүйгээр нийтлэх",
            "Messenger хэрэглэгчээс хувийн болон тухайн хүний санхүүгийн нууц мэдэээллийг зохих зөвшөөрөлгүйгээр шаардах",
            "Монгол Улсын хууль журамд хориглосон аливаа үйлдэлийг хийх",
            "Сонгуулийн зориулалтаар ашиглахыг хориглоно.",
          ],
        },
        {
          d: "Chatbot.mn систем дээр хэрэглэгчийн үүсгэсэн бот нь тухайн холболт хийгдсэн Facebook Messenger платформтой мэдээлэл солилцох зарчмаар ажиллах тул Facebook Messenger платформын үйлчилгээний нөхцөл болон нууцлалын дүрэм журмыг чанд мөрдөн ажиллах үүрэгтэй. Хэрэв хэрэглэгч нь тухайн Facebook Messenger платформын үйлчилгээний нөхцөлд үл нийцэх үйл ажиллагаа явуулсан тохиолдолд хэрэглэгчийн ботын үйл ажиллагааг зогсоох хүртэл арга хэмжээ авна.",
          d1: [],
        },
        {
          d: "Хэрэглэгч нь бусдад Chatbot.mn системийн нэвтрэх нууц үг, хэрэглэгчийн мэдээллээ бусдад дамжуулахгүй байх, үүнтэй холбоотой учирч болох аливаа эрсдэлээс сэргийлэх үүрэгтэй. Уг үүргээ биелүүлээгүйн улмаас үүссэн хохирлыг “Чатбот” ХХК нь хариуцахгүй болно.",
          d1: [],
        },
        {
          d: "Хэрэв хэрэглэгч өөрийн Account-ын нууцлалаа бусдад алдсан гэж үзвэл нууц үгээ солих эсвэл info@chatbot.mn болон 7744-1616 дугаарт яаралтай холбоо барина уу.",
          d1: [],
        },
      ],
    },
    {
      header: "Төлбөрт үйлчилгээ авах, цуцлах",
      desc: [
        {
          d: "Chatbot.mn систем нь үнэ төлбөргүй ашиглах боломжуудыг хэрэглэгчдэд олгохоос гадна төлбөрт үйлчилгээний боломжуудыг мөн санал болгодог.",
          d1: [],
        },
        {
          d: "Төлбөрт боломжийг ашиглахын тулд хэрэглэгч нь тухайн бот дээрх төлбөрт үйлчилгээг идэвхжүүлж дараа төлбөрт үйлчилгээний гэрээ байгуулах шаардлагатай.",
          d1: [],
        },
        {
          d: "Төлбөрт үйлчилгээний тариф нь Chatbot.mn сайт дээр нээлттэй байх ба тухайн сарын хэрэглэгчийн тоо болон төлбөрт боломжоос хамаарч ялгаатай үнэтэй байна.",
          d1: [],
        },
        {
          d: "Хэрэглэгч нь өөрийн үүсгэсэн бот тус бүрээр төлбөрт үйлчилгээг идэвхжүүлэх боломжтой ба үйлчилгээний төлбөр нь бот тус бүрээр тооцож бодогдоно.",
          d1: [],
        },
        {
          d: "Тухайн сарын үйлчилгээний төлбөрийн нэхэмжлэл нь дараа сарын 1-ний өдөр автоматаар бодогдон хэрэглэгчийн бүртгэлтэй имэйлээр илгээгдэх бөгөөд төлбөрийг дараа сарын 20-ны дотор төлөх шаардлагатай. Хэрэв дараа сарын 20-ны дотор төлбөрөө төлөөгүй тохиолдол хэрэглэгчийн ботын төлбөрт үйлчилгээний боломжуудыг ашиглах боломжгүй болно.",
          d1: [],
        },
        {
          d: "Хэрэглэгч нь хүссэн үедээ төлбөрт үйлчилгээг цуцлах боломжтой ба энэ тохиолдолд төлбөрт үйлчилгээг цуцалсан өдөр хүртэлх төлбөрийн нэхэмжлэл хэрэглэгчид илгээгдэнэ.",
          d1: [],
        },
        {
          d: "Төлбөрт үйлчилгээг цуцалсан тохиолдолд төлбөрт боломжууд идэвхгүй болох ба үнэгүй боломжуудыг цаашид үргэлжлүүлэн ашиглах боломжтой байна.",
          d1: [],
        },
        {
          d: "Харилцагч таны эзэмшдэг ботын төлбөрт багцын хугацаа дууссан тохиолдолд ҮНЭГҮЙ БАГЦ руу автоматаар шилжиж үргэлжлүүлэн ажиллах бөгөөд энэ үед чатбот үйлчилгээний цэсүүд хязгаарлагдаж, хэрэглэгчийн хандалтын дундуур ЗАР СУРТАЛЧИЛГАА давтамжтайгаар илгээгддэг болохыг анхаарна уу.",
          d1: [],
        },
      ],
    },
    {
      header: "Мессенжер холболт салгах, чатбот устгах болон account хаах",
      desc: [
        {
          d: "Хэрэглэгч нь Facebook хуудаснаасаа ботын холболтоо хүссэн үедээ салгах, холбох боломжтой ба салгах тохиолдолд тухайн Facebook messenger-тэй холбоотой харилцан ярианы түүх, messenger хэрэглэгчийн мэдээлэл устах болно. Facebook хуудаснаасаа ботоо салгасны дараа өөр шинэ Facebook хуудас холбох боломжтой.",
          d1: [],
        },
        {
          d: "Хэрэглэгч нь өөрийн үүсгэсэн ботыг устгах боломжтой ба энэ тохиолдолд тухайн боттой холбоотой мессеж блок, харилцан яриа, хэрэглэгчийн дата зэрэг бүх мэдээлэл устана.",
          d1: [],
        },
        {
          d: "Хэрэв хэрэглэгчийн үүсгэсэн бот дээр төлөгдөөгүй төлбөрийн нэхэмжлэл байгаа тохиолдолд ботыг устгах боломжгүй.",
          d1: [],
        },
        {
          d: "Хэрэглэгч нь өөрийн Chatbot.mn системийн account-ыг хаах боломжтой ба энэ тохиолдолд ботууд болон боттой холбоотой бүхий л мэдээлэл устана.",
          d1: [],
        },
        {
          d: "Хэрэглэгчийн account дээр ямар нэг төлөгдөөгүй төлбөрийн нэхэмжлэл байгаа тохиолдолд account-ыг хаах боломжгүй.",
          d1: [],
        },
      ],
    },
    {
      header: "Бусад",
      desc: [
        {
          d: "Энэхүү үйлчилгээний нөхцөл нь “Чатбот” ХХК-ний үйл ажиллагааны шинэчлэлтээс хамаарч нэмэлт өөрчлөлт орох боломжтой ба хэрэв нэмэлт өөрчлөлт орох тохиолдолд үйлчилгээ авч буй бүх хэрэглэгчдэд урьдчилан мэдэгдэнэ.",
          d1: [],
        },
        {
          d: "Chatbot.mn систем нь “Чатбот” ХХК-ний нэр дээр Харилцаа Холбооны Зохицуулах Хорооны албан ёсны сайтын бүртгэлтэй бөгөөд Chatbot.mn вебсайт болон системийн оюуны өмч нь хуулиар хамгаалагдсан.",
          d1: [],
        },
      ],
    },
  ],
  privacyTitle: "Нууцлалын бодлого",
  privacyList: [
    {
      header: "Ерөнхий болон Мэдээлэл цуглуулах",
      desc: [
        {
          d: "chatbot.mn ухаалаг чатбот бүтээгч платформ (Цаашид “Систем” гэх) нь дараах тохиолдолд иргэн, хуулийн этгээд /цаашид “Хэрэглэгч” гэх/ болон тэдгээрийн Фэйсбүүк хуудасны хэрэглэгчийн /цаашид “Чатботын хэрэглэгч” гэх/ мэдээллийг цуглуулна. Үүнд:",
          d1: [
            {
              d2: "Хэрэглэгч гэдэг нь chatbot.mn веб системд нэвтэрч бүртгэл үүсгэн өөрийн админ буюу удирдах эрхтэй Фэйсбүүк хуудсыг холбон чатботын хэрэглэгчдэд автоматаар хариулах чатботыг угсран үйлчилгээ үзүүлж буй хувь хүн, хуулийн этгээдийг хэлнэ.",
              d3: [],
            },
            {
              d2: "Чатбот хэрэглэгч гэдэг нь Хэрэглэгчийн холбосон Фэйсбүүк хуудасны “Send Message” товч болон бусад “Facebook Chat Plugin”-р дамжин автомат хариулагч чатботтой холбогдсон эцсийн хэрэглэгчдийг хэлнэ.",
              d3: [],
            },
            {
              d2: "Хэрэглэгч нь Системд бүртгүүлэхдээ имэйл, овог, нэр, утасны дугаар болон нууц үгээ оруулах ба систем нь хэрэглэгчийн үүсгэсэн нууц үгийг хүний оролцоогүйгээр криптограф аргачлалаар шифрлэн өгөгдлийн баазад хадгалагдах ба тайлж унших боломжгүй байна.",
              d3: [],
            },
            {
              d2: "Мөн Хэрэглэгч нь өөрийн Фэйсбүүк хуудсыг холбохын тулд Фэйсбүүк аккаунтаар нэвтрэх бөгөөд энэ үед систем нь Хэрэглэгчийн овог, нэр, имэйл, төрсөн огноо, хүйс нээлттэй тохиргоо бүхий мэдээлэл болон “Facebook App ID”, “Access Token” гэсэн хуудас холболт хийхэд шаардлагатай мэдээллийг хэрэглэгчийн зөвшөөрөлтэйгээр Фэйсбүүкээс авч хадгална.",
              d3: [],
            },
            {
              d2: "Чатбот хэрэглэгч нь Хэрэглэгчийн бүтээсэн автомат хариулагч чатботтой холбогдон харилцаж эхэлснээр системд бүртгэгдэх ба овог, нэр, имэйл, утас, төрсөн өдөр, нас хүйс зэрэг зөвшөөрөгдсөн хувийн мэдээлэл болон “Facebook PSID” (Page Scoped ID) системд авч хадгална.",
              d3: [],
            },
            {
              d2: "Хэрэглэгч нь Системийн төлбөр тооцоо болон онлайн шопын боломжуудыг ашиглах тохиолдолд байгууллагын гэрчилгээ, иргэний үнэмлэхийн хуулбар болон дансны мэдээллийг систем оруулж хадгалах шаардлагатай байдаг.",
              d3: [],
            },
          ],
        },
      ],
    },
    {
      header: "Мэдээллийг ашиглах нөхцөл",
      desc: [
        {
          d: "Хэрэглэгч болон Чатбот хэрэглэгчийн системд оруулсан бүх мэдээлэл тус тусын өөрийн өмч байх ба “Чатбот” ХХК нь Хэрэглэгч болон Чатботын хэрэглэгчийн зөвшөөрөлгүйгээр дор дурдсан нөхцөлөөс бусад хэлбэрээр ашиглахгүй болно.",
          d1: [
            {
              d2: "Хэрэглэгчийн хувьд:",
              d3: [
                "chatbot.mn нь “cloud” үйлчилгээний төлбөр тооцоо болон захиалгын мэдээллийг шалгах, тэдгээртэй холбоотой мэдэгдэл илгээх, үйлчилгээний төлбөр төлөлт хийх (Хэрэглэгчийн зөвшөөрөлтэйгээр)",
                "Хэрэглэгчтэй холбоотой харилцагчийн үйлчилгээ үзүүлэх (Хэрэглэгчийн зөвшөөрөлтэйгээр)",
                "Хэрэглэгчтэй холбоотой техникийн засвар үйлчилгээ хийх (Хэрэглэгчийн зөвшөөрөлтэйгээр)",
                "Хэрэглэгчид шинэ бүтээгдэхүүн болон хямдрал урамшууллын мэдээлэл хүргэх (Хэрэглэгчийн зөвшөөрөлтэйгээр)",
                "“Чатбот” ХХК нь chatbot.mn үйлчилгээний чанарыг сайжруулах, шинэ нэмэлт боломжуудыг зах зээлд нэвтрүүлэх зорилгоор хэрэглэгчийн чатботын болон чатботтой холбоотой мэдээллийг статистикийн аргачлалаар хувь хүн, хуулийн этгээдийн нууцлалгүй мэдээллийг авч ашиглах боломжтой.",
              ],
            },
            {
              d2: "Чатбот хэрэглэгчийн хувьд:",
              d3: [
                "Систем нь чатбот хэрэглэгчтэй харилцахын тулд тухайн чатбот хэрэглэгчийн “Facebook PSID”-г ашиглан мессеж илгээх",
                "Хэрэглэгч өөрийн чатботын хэрэглэгчийн мэдээллийг ашиглан өөрийн бизнесийн талаарх мэдээлэл илгээх, бараа бүтээгдэхүүн болон үйлчилгээний талаарх мэдээлэл илгээх (“Message Broadcasting” буюу Фэйсбүүкээс зөвшөөрсөн хүрээнд масс мессеж илгээнэ.)",
                "Хэрэглэгч нь системээр дамжуулан өөрийн чатботын хэрэглэгч нартаа харилцагчийн үйлчилгээ болон бусад үйлчилгээ үзүүлэхэд ашиглана.",
              ],
            },
          ],
        },
      ],
    },
    {
      header: "Хэрэглэгчийн мэдээллийг бусдад түгээх",
      desc: [
        {
          d: "",
          d1: [
            {
              d2: "Зөвхөн Монгол улсын хууль тогтоомжийн дагуу төрийн эрх бүхий байгууллагаас мэдээлэл гаргаж өгөх шаардлага ирсэн тохиолдолд",
              d3: [],
            },
          ],
        },
      ],
    },
    {
      header: "Хэрэглэгчийн мэдээллийг хадгалах",
      desc: [
        {
          d: "",
          d1: [
            {
              d2: "chatbot.mn систем дээр үүсэх Хэрэглэгчийн бүртгэлийн мэдээлэл, чатботын мэдээлэл, Чатбот хэрэглэгчийн мэдээлэл, харилцан ярианы түүх болон бусад бүх хэрэглэгчийн болон чатбот хэрэглэгчийн мэдээлэл нь нууцлал аюулгүй байдлын өндөр төвшинд “Чатбот” ХХК-ны “Мобинет” ХХК дата төв дэх сервер дээр хадгалагдана.",
              d3: [],
            },
            {
              d2: "Хэрэглэгч нь системд нэвтрэх нэр, нууц үгийг бусдад задруулахгүй байх үүрэгтэй.",
              d3: [],
            },
            {
              d2: "Нэвтрэх эрхээ бусдад алдсанаас үүдэлтэй Хэрэглэгчийн чатботод үүссэн аливаа хор, хохирлыг “Чатбот” ХХК хариуцахгүй болно. “Чатбот” ХХК нь Хэрэглэгчийн хандах эрхийг ашиглан мэдээлэлд нэвтрэх боломжгүй болохыг анхааруулж байна.",
              d3: [],
            },
          ],
        },
      ],
    },
    {
      header: "Хэрэглэгчийн мэдээллийг удирдах",
      desc: [
        {
          d: "",
          d1: [
            {
              d2: "Хэрэглэгч өөрийн хувийн болон компанийн мэдээллээ системийн дашбоард дээрээс хүссэн үедээ өөрчлөх боломжтой.",
              d3: [],
            },
            {
              d2: "Хэрэв Хэрэглэгч өөрийн чатботыг Фэйсбүүк хуудаснаас салгасан тохиолдолд автомат хариулагч нь идэвхгүй болох ба дахин идвэхтэй болгох хүртэл системд мэдээлэл нь хадгалагдана.",
              d3: [],
            },
            {
              d2: "Хэрэв Хэрэглэгч өөрийн чатботыг устгасан тохиолдолд чатбот болон чатботын хэрэглэгчийн мэдээллүүд системээс бүрэн устах ба дахин сэргээх боломжгүй байна.",
              d3: [],
            },
            {
              d2: "Хэрэв Хэрэглэгч нь өөрийн бүртгэл мэдээллийг идэвхгүй болгосон бол дахин идэвхгүй болтол системд хадгалагдана.",
              d3: [],
            },
            {
              d2: "Хэрэв Хэрэглэгч өөрийн бүртгэлийн мэдээллийг устгахыг хүссэн тохиолдолд info@chatbot.mn цахим шуудангийн хаягт хүсэлтээ илгээснээр бид ажлын 3-5 хоногийн дотор chatbot.mn системээс бүртгэлийн мэдээллийг устгаад мэдэгдэх болно.",
              d3: [],
            },
          ],
        },
      ],
    },
    {
      header: "Нууцлалын бодлогын шинэчлэлт",
      desc: [
        {
          d: "",
          d1: [
            {
              d2: "“Чатбот” ХХК нь Нууцлалын бодлогод өөрчлөлт оруулах эрхтэй. Нууцлалын бодлогод өөрчлөлт оруулсан тохиолдолд тухай бүр chatbot.mn сайт дээр нийтэд нээлттэй нийтлэх бөгөөд Хэрэглэгч нь танилцах үүрэг хүлээнэ.",
              d3: [],
            },
          ],
        },
      ],
    },
  ],
  //////////////////////////////////////////////////////////////
  heroStat: {
    col1: "Чатбот-руу хандсан хэрэглэгчид",
    col2: "Брэндүүдийн виртуал туслах, бот хөгжүүлэлт хийгдсэн",
    col3: "Бизнесийн байгууллага, хэрэглэгчидтэй",
  },
  products: [
    {
      id: 0,
      type: "Онлайн дэлгүүр",
      description:
        "Хэрэглэгч та хайсан бараа бүтээгдэхүүнээ ангиллаар нь хялбархан хайж олох бөгөөд бүтээгдэхүүнийхээ талаарх дэлгэрэнгүй мэдээллийг аваад зогсохгүй сонгосон бараагаа шууд худалдан авах боломжтой",
    },
    {
      id: 1,
      type: "Коммент хариулт",
      description:
        "Фэйсбүүк хуудасны пост доор комментоор мэдээлэл авах хүсэлтэй хэрэглэчиддээ цаг алдалгүйгээр бүгдэд нь 24/7-ийн турш автоматаар комментод хариулж дэлгэрэнгүй мэдээллийг мессенжерээр илгээнэ",
    },
    {
      id: 2,
      type: "Live Chat",
      description:
        "Хэрэглэгчдийн зан төлөв, онцлог нөхцөл байдал, хүсэлт шаардлага, өөрт тохирох үйлчилгээг авах хүсэл сонирхолоос үүдэж мэдээлэл авах үедээ ажилтантай холбогдох хэрэгцээ хэрэглэгчдэд гардаг.",
    },
    {
      id: 3,
      type: "Хүргэлт",
      description:
        "Захиалагч бараа бүтээгдэхүүнийхээ төлбөрийг төлөөд хүргэлтийн компаниа сонгоод захиалсан хаягийнхаа дагуу хүргүүлээд авах нь дэлгүүр цэсний цогц шийдэл юм.",
    },
    {
      id: 4,
      type: "Онлайн гэрээ",
      description:
        "Гэрээнд шаардлагатай мэдээллийг хэрэглэгчээс асуулгын формоор дамжуулан авч гэрээний драфтад автоматаар байршуулсанаар гэрээ бэлэн болж гэрээ байгуулагч 2 талд мэйлээр зэрэг илгээгдэнэ.",
    },
  ],
  productHeader3: "Багуудын уялдаа холбоо",
  productSlide: [
    {
      id: 0,
      type: "МАРКЕТИНГИЙН БАГ",
      title:
        "Оновчтой маркетинг, үнэнч хэрэглэгч, тогтвортой харилцааг бий болгодог.",
      description: [
        "Компани болон хэрэглэгчийн хоорондын харилцааг бий болго.",
        "Хэрэглэгчийн туршлагыг нэмэгдүүл",
        "Брэндийн эерэг дүр төрхийг бий болго",
        "Брэндийн талаар хангалттай мэдлэгтэй, брэндэд хайртай хэрэглэгчдийг бэлд",
      ],
    },
    {
      id: 1,
      type: "БОРЛУУЛАЛТЫН БАГ",
      title:
        "Технологийн дэвшлээр бизнесийн хэв маягийг шинэчилж борлуулалт хийхэд ашигла.",
      description: [
        "Борлуулалтын сувгаа тэл",
        "Боломжит хэрэглэгчдийн мэдээллийг цуглуул",
        "Борлуулалтын багийн үйл ажиллагааг дэмж",
        "Борлуулалтын процессыг автоматжуул",
      ],
    },
    {
      id: 2,
      type: "ҮЙЛЧИЛГЭЭНИЙ БАГ",
      title:
        "Үйлчилгээнээс авсан сэтгэл ханамж худалдан авалт дахин хийх сэдлийг төрүүлдэг",
      description: [
        "24/7 цагийн турш хэрэглэгчдэд хэрэгцээт мэдээллийг нь хүргэ",
        "Санал, гомдлыг системтэйгээр түргэн хугацаанд шийдвэрлэ",
        "Хэрэглэгчийн үйлчилгээний багийн бүтээмжийг нэмэгдүүл",
        "Үнэнч хэрэглэгчдийг бэлдэх замаар давтан худалдан авалтыг нэмэгдүүл",
      ],
    },
  ],
  footer: {
    title: "CHATBOT-Г ТУРШИЖ ҮЗЭЭРЭЙ",
    subtitle: "ХЯЛБАР БӨГӨӨД ЭХ ХЭЛ ДЭЭРЭЭ ЧАТБОТОО ЭХЛҮҮЛЦГЭЭЕ",
    col1: "Чатбот",
    row1: "Бидний тухай",
    row2: "Түгээмэл асуулт, хариулт",
    row3: "Чатбот хэрэглэгчдийн групп",
    row4: "Холбоо барих",
    col2: "БҮТЭЭГДЭХҮҮН",
    row5: "Бот хөгжүүлэлт",
    row6: "Чадавхжуулах сургалт",
    row7: "Үйлчилгээний багцууд",
    row8: "Харилцагчдын үйлчилгээ",
    col3: "ТУСЛАМЖ",
    row9: "Хөтөч",
    row10: "Онлайн зөвөлгөө",
    row11: "Гарын авлага",
    row12: "Темплейт",
    copyright: "Бүх эрх хуулиар хамгаалагдсан",
    year: "он",
  },
  about: {
    title: "БИДНИЙ ТУХАЙ",
    p1: "Техник технологийн салбарт олон жил туршлага хуримтлуулсан Монгол залуучуудын үүсгэн байгуулсан NSP ХХК нь 2019 оноос эхлэн Чатбот платформыг хөгжүүлэн Start Up-аас эхлэн бие даасан Чатбот ХХК-ыг үүсгэн байгуулжээ.",
    p2: "Төр, хувийн хэвшил, бизнесийн үйл ажиллагаа эрхэлдэг нийт 3500 гаруй байгууллагуудын өдөр тутмын харилцагчийн үйлчилгээг автоматжуулж, технологийн шинэ боломжуудыг бизнесүүдэд нэвтрүүлсээр байна.",
    row1Title: "ЭРХЭМ ЗОРИЛГО",
    row2Title: "ҮНЭТ ЗҮЙЛС",
    row3Title: "АЛСЫН ХАРАА",
    row1: "Монгол залуучуудын оюуны чадал, эв нэгдлийг дэлхийн тавцанд гаргах",
    row2: ["Баг хамт олон", "Алсын хараа", "Тууштай хөдөлмөр"],
    row3: "Бид Чатбот платформыг улам хөгжүүлэн Зүүн Өмнөд Ази болон дэлхийн зах зээлд өрсөлдөхүйц бүтээгдэхүүн болгож олон төрлийн сувгуудаар үйлчилгээгээ нэвтрүүлэхээр зориод байна.",
  },
  input: {
    email: "Имэйл",
    pwd: "Нууц үг",
    lName: "Овог",
    fName: "Нэр",
    phone: "Утас",
    forgot: "Нууц үг мартсан?",
    remember: "Намайг сана",
    signinif: "Бүртгэлтэй юу? нэвтрэх",
    signupif: "ХЭРЭВ ТА БҮРТГЭЛГҮЙ БОЛ", //end darj burgtuulne uu gdgiig ni tusad ni ene hesegt bicheed ogoorei
    signupifLink: "ЭНД ДАРЖ БҮРТГҮҮЛНЭ ҮҮ.", //end darj burgtuulne uu gdgiig ni tusad ni ene hesegt bicheed ogoorei
    acceptTerm: '"БҮРТГҮҮЛЭХ" ДЭЭР ДАРСНААР ТА БҮРТГЭЛ ҮҮСГЭЖ, МАНАЙ',
    acceptTerm2: "ЗӨВШӨӨРЧ БАЙНА.",
    forgotTitle: "БҮРТГҮҮЛСЭН ИМЭЙЛ ХАЯГАА ОРУУЛНА УУ",
    forgotButton: "Нууц үг сэргээх",
    button: "Facebook-р нэвтрэх",
    privacy: "НУУЦЛАЛЫН БОДЛОГЫГ",
    term: "ҮЙЛЧИЛГЭЭНИЙ НӨХЦӨЛ",
    label1: "Имэйл оруулна уу",
    label2: "Нууц үг оруулна уу",
    label3: "Овог оруулна уу",
    label4: "Нэр оруулна уу",
    label5: "Утас оруулна уу",
  },
  tutorial: {
    h2: "Бидэнтэй хамт \nхөгжөөрэй",
    h3: "Сургалтын сэдвүүд",
    h4: "Чатботын боломж №",
    button: "Дэлгэрэнгүй",
    input: "Хайх...",
    templateTitle: "Холбоотой тэмплейтүүд",
    request: "Та танхимын сургалт авахыг хүсэж байна уу?",
    formName: "Таны нэр",
    formOrg: "Албан байгууллага",
    formDate: "Өдөр /Тухайн өдрийн 10:00 цагт сургалт авах боломжтой/",
    formEmail: "Таны имэйл хаяг",
    formPhone: "Тантай холбоо барих утасны дугаар",
    formSubmit: "Хүсэлт илгээх",
    phoneLabel: "Утасны дугаар: ",
    phone: "77441616",
    addressLabel: "Манай хаяг: ",
    address:
      "Сүхбаатар дүүрэг, Энхтайваны өргөн чөлөө, 15A/5, Амбассадор оффис, 1 давхар 104, Улаанбаатар, Монгол - 14200",
    paragraph:
      "Та бүхэн энэхүү сургалтаар харилцааны маркетингийн гол хэрэглэгдэхүүн болох ЧАТБОТ үйлчилгээний талаарх дэлгэрэнгүй мэдээллүүд болон боломжууд, хэрхэн яаж амжилттай ашиглах зэрэг гол мэдээллүүдийг мэргэшсэн бот хөгжүүлэгчдээс авах боломжтой.",
    cards: [
      {
        id: 1,
        title: "Мэдээлэл байршуулах",
        url: "https://www.youtube.com/embed/GBHuScg_5Mc?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Та бүхэн бүтээгдэхүүн үйлчилгээнийхээ талаарх бүхий л төрлийн мэдээллийг зураг, текст, видео, файл, линк холбоос гэх мэт олон төрлөөр 7 хоногийн аль ч цагт хэрэглэгчдэдээ тогтмол хүргэх боломжтой.",
      },
      {
        id: 2,
        title: "Харилцан яриа",
        url: "https://www.youtube.com/embed/YhdgfHWzPAU?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Хүмүүсээс ирсэн асуултуудын түлхүүр үгсийг таньж тухайн асуусан асуултад тохирсон хариулт, дэлгэрэнгүй мэдээ мэдээлэл илгээх боломжтой.",
      },
      {
        id: 3,
        title: "Түгээмэл асуулт хариулт",
        url: "https://www.youtube.com/embed/YxUOWzF2FuI?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Хэрэглэгч харилцагчдын зүгээс байнга асуугддаг асуултуудаар түгээмэл асуулт болон тэдгээрийн хариултуудыг үүсгэн хэрэглэгчдэд автоматаар хариулж, хурдан хялбар аргаар мэдээлэл хүргэх боломжтой.",
      },
      {
        id: 4,
        title: "Live agent",
        url: "https://www.youtube.com/embed/qAoZvdaLWJw?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Харилцагч нь чатботын автомат мэдээллийн урсгалыг зогсоож ажилтантай харилцаж, яг өөрт хэрэгцээтэй дэлгэрэнгүй мэдээллийг авах боломжтой. Мөн түүнчлэн байгууллага хэрэглэгчдээрээ 1-5 хүртэлх одоор операторыг үнэлүүлж, цугларсан мэдээллийг үйлчилгээнийхээ чанарыг тодорхойлж сайжруулахад ашигладаг.",
      },
      {
        id: 5,
        title: "Асуулгын форм",
        url: "https://www.youtube.com/embed/OAgFNUG1NTg?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Чатботоор дамжуулан асуулгын форм үүсгэж хэрэглэгчээс бүтээгдэхүүн үйлчилгээний талаарх сэтгэгдэл, төрөл бүрийн судалгаа, мэдээ мэдээллийг чатлах хэлбэрээр цуглуулах боломжтой.​",
      },
      {
        id: 6,
        title: "Веб форм",
        url: "https://www.youtube.com/embed/Hth3BvrSgyo?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Чат дотор нэмэлт цонх үүсгэн асуумж бөглүүлж, хэрэглэгчдээс дата цуглуулах зориулалттай цэс юм. ​Вебформыг бөглөсөн тохиолдолд хариултыг мэйлээр хүлээн авч болох ба цугларсан хариултуудыг дашбоардоос  эксел файлаар татаж авах боломжтой.​​",
      },
      {
        id: 7,
        title: "Масс мессеж",
        url: "https://www.youtube.com/embed/YTgT3gQAFIA?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "24 цагийн дотор танай чатбот руу хандсан нийт хэрэглэгчид рүү эсвэл сегментүүдэд​​ ангилсан хэрэглэгчдийн бүлгээр, яг одоо болон төлөвлөгөөт хугацаа зааж, стандарт мессежний төрлүүдээр зар сурталчилгаа, мэдээ мэдээлэл илгээх боломжтой.",
      },
      {
        id: 8,
        title: "Коммент хариулт",
        url: "https://www.youtube.com/embed/yaNh9Nteaqk?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description: `Фейсбүүк пэйж дээр нийтлэгдсэн постын доор хэрэглэгчид коммент бичих үед автоматаар коммент Reply үлдээж, чатаар 2 төрлийн хариулт өгөх боломжтой. \n1. Хэрэглэгчийн бичсэн комментоос түлхүүр үгийг таньж тохируулсан хариултыг илгээнэ. \n2. Хэрэглэгч юу ч бичсэн тогтмол нэг хариулт өгөх тохиргоо хийж болно. \n\nМөн үүнээс гадна сөрөг сэтгэгдлүүдийг харилцан ярианд хадгалсан түлхүүр үгсээр таньж уг комментыг нийтлэхгүйгээр устгах боломжтой.​​`,
      },
      {
        id: 9,
        title: "Харилцагчийн дата",
        url: "https://www.youtube.com/embed/eENdtu7CSis?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Чатботоор хандсан хэрэглэгч, харилцагчдын сегмент, чатын түүх, асуулга болон вебформын үр дүн, тайлан гэх мэт бүхий л дата мэдээллүүд чатботын дашбоард дотор хадгалагддаг.",
      },
      {
        id: 10,
        title: "Хэрэглэгчийн таг",
        url: "https://www.youtube.com/embed/e1dg50_w9GE?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Чатботоор хандсан хэрэглэгчдийг зан төлөв, үйлдлээр нь тагуудад хуваан ангилах мөн ялгаатай бүлгүүд үүсгэн тэдгээрт тохирсон мэдээ мэдээллийг түгээх, идэвхжүүлэх боломжтой.",
      },
      {
        id: 11,
        title: "Статик хуудас",
        url: "https://www.youtube.com/embed/57HuwrdPk4o?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Статик хуудасны тусламжтай мессенжер дотор нэмэлт цонх үүсгэн мэдээлэл харуулах боломжтой.",
      },
      {
        id: 12,
        title: "Систем интеграц",
        url: "https://www.youtube.com/embed/FXZgnKigRkQ?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Байгууллагуудын дотоод үйл ажиллагаандаа ашигладаг Google sheet, Google calendar, Hubspot гэх мэт системүүдтэй интеграци хийх боломжтой.",
      },
      {
        id: 13,
        title: "Аналитикс",
        url: "https://www.youtube.com/embed/Ez1eO5ce6E4?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Чатботоор хандаж байгаа хэрэглэгчдийн хандалт болон үйлдлүүдийн тайланг он, сар, өдрөөр шүүж харах, мөн excel файлаар татаж авах боломжтой.",
      },
      {
        id: 14,
        title: "Монитор",
        url: "https://www.youtube.com/embed/qfWTKhNH660?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Монитор цэс нь ботын алдааны мэдээлэл, хэрэглэгчдэд хариулаагүй асуултууд,  системийн хэрэглэгчдийн хийсэн үйлдлүүдийг он сар өдрөөр тус тус харуулна.",
      },
      {
        id: 15,
        title: "Дэлгүүр 2.0",
        url: "https://www.youtube.com/embed/-7VLTCTJeg0?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Шинэ Дэлгүүр 2.0 нь Хэрэглэгч мессенжерийнхээ цонхноос гаралгүйгээр, бараа бүтээгдэхүүнийг  сагсалж, худалдан авалт хийхэд зориулагдсан дотоодын төлбөрийн системүүд болох QPay, SocialPay-ээр дамжиж төлбөр тооцоогоо хийх боломжтой таны онлайн дэлгүүр юм. \nТа манай Дэлгүүр 2.0 цэсийн тусламжтай:\n1. Бүтээгдэхүүн байршуулах \n2. Хямдрал тохируулах \n3. Хэрэглэгчдэд зориулсан мэдээллүүд оруулах \n4. Бүтээгдэхүүнээ ангилах \n5. Онцлох бүтээгдэхүүн үүсгэх \n6. Баннер байршуулах \n7. Коллекц үүсгэх \n8. Countdown цаг тохируулах \n9. Салбарын үлдэгдэл оруулах болон \n10. Хэрэглэгч тухайн сонгосон бүтээгдэхүүнээ сагслах \n11. Хүргэлтийн хаягаа тохируулах \n12. Төлбөр тооцоогоо онлайнаар хийж бараа бүтээгдэхүүн худалдаж авах боломжтой.",
      },
      {
        id: 16,
        title: "Төлбөрийн холболт",
        url: "https://www.youtube.com/embed/FpRjP7BcEJU?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Чатбот нь зөвхөн байгууллага болон хэрэглэгч хоорондын харилцааг зохицуулдаг автомат хариулагч биш бөгөөд дотоодын төлбөрийн системүүдтэй холбогдож төрөл бүрийн бүтээгдэхүүн, үйлчилгээний төлбөр тооцоог шийдэх боломжтой.",
      },
      {
        id: 17,
        title: "Хүргэлт",
        url: "https://www.youtube.com/embed/ucvgziyd5uM?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Чатбот нь онлайн худалдааны Дэлгүүр 2.0 цэсийг цогцоор нь шийдэхийн тулд дотоодын хүргэлтийн компаниудтай системийн холболт хийж эхэлснээрээ байгууллага, хэрэглэгчдэд бараа бүтээгдэхүүнээ хүргэх үйл явцыг автоматжуулан, илүү хялбар болгож байна.",
      },
      {
        id: 18,
        title: "Захиалга",
        url: "https://www.youtube.com/embed/JInmNgDLLwA?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Захиалга цэсийн тусламжтай бүтээгдэхүүн үйлчилгээний захиалга авах, QPAY болон Social Pay-р төлбөр хийх боломжтой. Мөн зөвхөн бараа бүтээгдэхүүн худалдаалахаас гадна төлбөр тооцоо хийгдэх боломжтой бүхий л зүйлсэд ашиглагддаг онцлогтой, Жишээ нь: Сургалтын бүртгэл болон төлбөр хийх, хандив цуглуулах, бүртгэл баталгаажуулах гэх мэт.",
      },
      {
        id: 19,
        title: "Бот үүсгэх, хуудас холбох",
        url: "https://www.youtube.com/embed/rGJCF4wNNR4?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Та хянах самбараар дамжин шинээр бот үүсгэх, үүсгэсэн нийт ботуудаа харах, идэвхтэй, идэвхгүй төлөвөөр шүүх, нэрээр нь хайх, загвар ботуудаас өөрийн үйл ажиллагаанд тохиромжтой ботны загваруудтай танилцах, мөн өөрийн ботоо чатбот системд бүртгэлтэй өөр нэгэн хэрэглэгчид мэдээлэл солих, засвар хийх гэх мэт эрх өгөн хуваалцах боломжтой.",
      },
      {
        id: 20,
        title: "Мессеж блок",
        url: "https://www.youtube.com/embed/kR57nSi3lOw?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Мессеж блок цэс нь хэрэглэгчдэдээ хүргэх мэдээллүүдээ эмх цэгцтэйгээр байршуулж, тэдгээрийг хооронд нь уялдуулж холбон ажиллагааг тестэлж, ботыг бэлэн болгох үндсэн талбар юм.",
      },
      {
        id: 21,
        title: "Тохиргоо: Ерөнхий",
        url: "https://www.youtube.com/embed/r1Dq405xpKo?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Тохиргоо цэсийн Ерөнхий хуудас нь эхлэл, эхлэл асуулт, зөвшөөрөгдсөн домэйн, персона, ботын тохиргоо гэсэн 5 хэсэгтэй бөгөөд та эдгээр тохиргоонуудын тусламжтай хэрэглэгчид асуух боломжтой хамгийн эхний асуултыг тохируулах, page-д бүртгэлтэй зөвшөөрөгдсөн домайнуудыг таних, ботны аватар буюу нүүр зургийг сонгох гэх мэт ерөнхий тохиргоонуудыг хийх боломжтой.",
      },
      {
        id: 22,
        title: "Тохиргоо: Мэндчилгээ",
        url: "https://www.youtube.com/embed/xIn51RFCXXc?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Мэндчилгээ цэсийн тусламжтай баяр наадмын, өдөр тутмын, харилцан ярианы эхлэл болон үдэлтийн мэндчилгээ мессежнүүдийг тохируулах боломжтой.",
      },
      {
        id: 23,
        title: "Тохиргоо: Хэрэглэгч нэвтрэх",
        url: "https://www.youtube.com/embed/FQqOZJ8l_XY?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          '"Хэрэглэгч нэвтрэх" цэсийн тусламжтай танай байгууллагын чатбот руу анх удаа Get started дарж хандаж байгаа хэрэглэгчдэд харилцан ярианы хамгийн эхэнд өгөх шаардлагатай мэдээллийг илгээх болон асуух асуултуудаа тохируулдаг хэсэг бөгөөд байгууллагууд ихэвчлэн хэрэглэгчдийнхээ утасны дугаар, мэйл хаягийг бүртгэж дата цуглуулахдаа ашигладаг.',
      },
      {
        id: 24,
        title: "Тохиргоо: Үндсэн цэс",
        url: "https://www.youtube.com/embed/0nXYYo1kpig?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Үндсэн цэс нь хэрэглэгчдэд хамгийн ойр буюу мессенжерийн цонхны баруун доод буланд байрлах товч бөгөөд байгууллага хамгийн гол үйлчилгээ мэдээллүүдээ энэхүү цэсэд байрлуулах нь үр дүнтэй байдаг.",
      },
      {
        id: 25,
        title: "Хэрэглэгч",
        url: "https://www.youtube.com/embed/xTIwo1l3A-g?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Хэрэглэгч цэсийн тусламжтай тухайн байгууллагын чатбот-д бүртгэгдсэн буюу Get started дарж хандалт хийсэн бүх хэрэглэгчдийн жагсаалтыг харж болдог бөгөөд мөн тухайлсан 1 хэрэглэгчийн чатын түүхийг харах болон хариу мэдээлэл илгээх боломжтой.",
      },
      {
        id: 26,
        title: "Хэрэглэгчийн аттрибут",
        url: "https://www.youtube.com/embed/vefrnuDnGyY?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Атрибут гэдэг нь тухайн хэрэглэгчийг илэрхийлж буй шинж чанарууд бөгөөд байгууллага нь хэрэглэгчдээс авахыг хүссэн мэдээллүүдээ цуглуулж хэрэглэгчийн атрибут цэсэд хадгалж, үүний тусламжтай тухайн хэрэглэгчдийг нийтлэг шинж чанараар нь бүлэглэж, тэдгээрт масс мессеж илгээх зэргээр маркетингийн идэвхжүүлэлтийг оновчтой хийх боломжтой.",
      },
      {
        id: 27,
        title: "Хэрэглэгчийн бүлэг",
        url: "https://www.youtube.com/embed/vWOzDqq3cPQ?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Бүртгэгдсэн хэрэглэгчдийг таг, атрибутаар нь сонгон бүлэглэж, сегмент үүсгэн масс мессеж илгээх боломжтой.",
      },
      {
        id: 28,
        title: "Захиалгат зурвас",
        url: "",
        description:
          "Захиалгат мессеж цэсийг тохируулсан мэдээллийг, тохируулсан цагт илгээж хэрэглэгчийн дутуу хийсэн үйлдлүүдийг гүйцээх, борлуулалтаа амжилттай болгох, хийж байсан үйлдлийг сэргээх, бүтээгдэхүүн үйлчилгээгээ дахин сануулах гэх зэргээр ашигладаг. \n\nМасс мессеж нь олон хүнд нэгэн зэрэг илгээгддэг нэгдсэн мессеж бол захиалгат мессеж нь тухайн хүнд зориулагдсан мессеж юм.",
      },
      {
        id: 29,
        title: "JSON API",
        url: "",
        description:
          "Хэрэглэгч нарт API холболт хийж хувьсах мэдээлэл харуулах үед ашиглагдана. Жишээ нь салбарын мэдээлэл, цагийн хуваарь, бүтээгдэхүүний мэдээлэл, тухайн өдрийн цаг агаар, сонгогдсон нэг валютын ханш гэх мэт.",
      },
      {
        id: 30,
        title: "Агуулга",
        url: "",
        description:
          "Агуулга цэсийн тусламжтай блокуудыг багцалж 1 агуулга болгон олон товчоор зэрэг дуудагддаг мэдээллүүдийг тохируулах боломжтойгоос гадна үүсгэсэн агуулгууддаа зэрэглэл өгөн хэрэглэгчдэд хамгийн түрүүнд хүргэхийг хүссэн мэдээллүүдээ эрэмбэлэх боломжтой.",
      },
    ],
    templates: [
      {
        id: 1,
        title: "Онлайн худалдаа",
        img: ecommerce,
        button: "Тэмплейт үзэх",
        desc: "Бүтээгдэхүүн, үйлчилгээгээ онлайнаар худалдаалах боломж",
        list: [
          "Бүтээгдэхүүний ангилал харуулах",
          "Бүтээгдэхүүний төрөл харуулах",
          "Бүтээгдэхүүний дэлгэрэнгүй мэдээлэл",
          "Бүтээгдэхүүний үнэ хямдрал",
          "Бүтээгдэхүүн сагсллах, худалдаж авах",
          "Захиалга хийх, хүргэлт, төлбөр төлөх",
          "Хямдрал урамшууллын хугацааг тохируулах",
          "Салбарын үлдэгдэл харуулах",
        ],
      },
      {
        id: 2,
        title: "Гоо сайхан, эрүүл мэнд",
        img: hospital,
        button: "Тэмплейт үзэх",
        desc: "Үнэнч үйлчлүүлэгчдийн чатботын тусламжтай бий болго",
        list: [
          "Гоо сайхны зөвлөгөө мэдээллүүд",
          "Мэргэжлийн гоо сайханчийн зөвлөгөө өгөх",
          "Үйлчлүүлэгчдийн асуултад хариулах",
          "Тохирсон бүтээгдэхүүн, үйлчилгээ санал болгох",
          "Үйлчилгээний цаг захиалах",
          "Гоо сайхны сонжоо, quiz явуулах",
          "Ажлын цагийн хуваарь",
          "Хаяг байршлын мэдээлэл хүргэх",
        ],
      },
      {
        id: 3,
        title: "Ресторан, зоогийн газар",
        img: restaurant,
        button: "Тэмплейт үзэх",
        desc: "Ресторан, Зоогийн газар, Кафе, Bar, Pub, Караоке...",
        list: [
          "Меню-гээ танилцуулах",
          "Санал, хүсэлт цуглуулах",
          "Хоолны захиалга авах",
          "Ширээний захиалга авах",
          "Ажиллах цагийн хуваарь",
          "Хаяг байршлын мэдээлэл",
          "Хямдрал урамшууллын мэдээлэл",
          "Үйлчлүүлэгчдийн асуултад хариулах",
        ],
      },
      {
        id: 4,
        title: "Барилга, үл хөдлөх",
        img: realestate,
        button: "Тэмплейт үзэх",
        desc: "Төрөл бүрийн үл хөдлөх хөрөнгийн мэдээллүүдийг идэвхжүүлэх",
        list: [
          "Агентуудтай уулзах цаг авах",
          "Үл хөдлөх хөрөнгийн зөвлөгөө, мэдээлэл",
          "Үл хөдлөх хөрөнгийн зарууд",
          "Түрээс болон худалдаалах бүхий л мэдээлэл",
          "Үл хөдлөх хөрөнгийн заруудыг бүртгэх",
          "Онцгой заруудыг харуулах",
          "Заруудыг ангилах, түгээх",
          "Худалдан авагчдын мэдээллийг бүртгэх",
        ],
      },
      {
        id: 5,
        title: "Боловсрол",
        img: education,
        button: "Тэмплейт үзэх",
        desc: "Оюутнуудад мэдээлэл түгээж, элсэлтийн бүртгэлийг автоматжуул",
        list: [
          "Түгээмэл асуугддаг асуултуудад хариул",
          "Шаардлагатай мэдээ, мэдээллийг түгээ",
          "Элсэлтийн хөтөлбөр, журмыг танилцуул",
          "Оюутнуудын бүртгэлийг автоматжуул",
          "Хичээл сонголт, хуваариудыг илгээ",
          "Багш, тэнхимүүдийн мэдээллийг түгээ",
          "Шинээр элсэгчдэд мэдээлэл түгээ",
          "Санал хүсэлтүүдийг цуглуул",
        ],
      },
    ],
  },
  notFound: "Хуудас олдсонгүй",
};
