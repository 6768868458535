import vert1 from "../vert-1.png";
import vert2 from "../vert-2.png";
import vert3 from "../vert-3.png";
import vert4 from "../vert-4.png";
import grid1 from "../grid-1.png";
import grid2 from "../grid-2.png";
import grid3 from "../grid-3.png";
import grid4 from "../grid-4.png";
import grid5 from "../grid-5.png";
import grid6 from "../grid-6.png";
import grid7 from "../grid-7.png";
import grid8 from "../grid-8.png";
import emch from "../emch.png";
import euro from "../euro.jpeg";
import pn from "../pn.png";
import itzone from "../itzone.png";
import ecommerce from "../ecommerce.png";
import education from "../education.png";
import hospital from "../hospital.png";
import realestate from "../realestate.png";
import restaurant from "../restaurant.png";

export const ru = {
  nav: {
    li1: "Сервис",
    li2: "Цена",
    li3: "Возможности",
  },
  navFeatures: [
    {
      id: 0,
      type: "Блог",
      route: "https://blog.chatbot.mn/",
    },
    {
      id: 1,
      type: "Руководство пользователя",
      route: "https://help.chatbot.mn/",
    },
    {
      id: 2,
      type: "Помощь",
      route: "https://www.messenger.com/t/308105179819485",
    },
    {
      id: 3,
      type: "Онлайн обучение",
      route: "/tutorials",
    },
  ],
  hero: {
    h2: "Ваш бизнес-план",
    h1: "ПРЕВРАТИТЕ СВОИХ КЛИЕНТОВ В ПОЛЬЗОВАТЕЛЕЙ",
    h3: "Виртуальный помощник для каждого",
    companies:
      "Лидеры отрасли, успешно интегрировавшие сервис ЧАТБОТ в свою деятельность",
    bubble1: "Привет. Chatbot.mn, автоматический чат-бот, отвечает.",
    bubble2: "Что такое чат-бот?",
    downloadLink:
      "https://chatbot.mn/uploads/home/Taniltsuulga%20English%20NEW%205.pdf",
  },
  vertTitle: "Важный инструмент чатбот-маркетинга",
  vertData: [
    {
      id: 0,
      title: "УСИЛИВАЙТЕ МАРКЕТИНГОВЫЙ КАНАЛ",
      description:
        "Используйте чат-бота в своей маркетинговой стратегии, чтобы привлекать новых клиентов, обслуживать их, и в конечном итоге превратить их в лояльных клиентов вашей компании и бренда.",
      img: vert1,
    },
    {
      id: 1,
      title: "УЛУЧШИТЬ И РАЗВИТЬ СВОЮ ДЕЯТЕЛЬНОСТЬ",
      description:
        "Чат-бот дает возможность собирать данные о клиентах, которые можно анализировать и использовать для улучшения и развития вашего бизнеса.",
      img: vert4,
    },
    {
      id: 2,
      title: "СДЕЛАЙТЕ СВОЕ ОБСЛУЖИВАНИЕ КЛИЕНТОВ ЭФФЕКТИВНЕЙ",
      description:
        "С помощью чат-бота вы можете принимать жалобы и предложения клиентов, предоставлять им необходимую информацию 24/7.",
      img: vert3,
    },
    {
      id: 3,
      title: "УВЕЛИЧЬТЕ ПРОДАЖИ И ДОХОД",
      description:
        "Встраивайте продукты в ваш магазин, производите расчеты, регистрируйте товары, отслеживайте остатки, осуществляйте доставку и т.д., чтобы продавать онлайн.",
      img: vert2,
    },
  ],
  integration:
    "СВЯЖИТЕ С ИНСТРУМЕНТАМИ, КОТОРЫЕ ВЫ ПОСТОЯННО ИСПОЛЬЗУЕТЕ В СВОЕЙ РАБОТЕ.",
  integrationDesc:
    "Связывая технологический стек с нашими приложениями, вы увеличиваете продуктивность команды, делаете ваших клиентов счастливее и делаете больше с помощью чат-бота.",
  subscribeM:
    "Вы можете подписаться на интересные новости и информацию, зарегистрировав свой адрес электронной почты в нижнем поле.",
  subscribeW:
    "Вы можете подписаться на интересные новости и информацию о маркетинге в области коммуникаций, технологических тенденциях, возможностях развития чат-ботов, новых продуктах и услугах, зарегистрировав свой адрес электронной почты в нижнем поле.",
  priceTitle:
    "Выберите из следующих пакетов, исходя из целей и бизнеса вашей организации.",
  price: [
    {
      id: 0,
      type: "ПАКЕТ PRO",
      price: "300,000₮",
      userCount: "Количество пользователей",
      description:
        "Пакет, который помогает индивидуальным предпринимателям и малым и средним предприятиям вести эффективное общение с клиентами, управлять этим общением и его развитием.",
      button: "Регистрация",
      link: "",
    },
    {
      id: 1,
      type: "ENTERPRISE PACKAGE",
      price: "900,000₮",
      userCount: "БЕЗ ОГРАНИЧЕНИЯ ПО КОЛИЧЕСТВУ ПОЛЬЗОВАТЕЛЕЙ",
      description:
        "Пакет, который помогает повысить эффективность крупных предприятий, банковской финансовой сферы, коммерческого обслуживания, производственных предприятий, службы клиентского обслуживания, отделов продаж и маркетинга.",
      button: "Получить информацию",
      link: "https://chatbot.mn/webform/dynamic/5538?__btn__=357219&psid=4090596247724602&__bid__=4",
    },
    {
      id: 2,
      type: "VIP",
      price: "Без ограничения по количеству пользователей",
      userCount: "Без ограничения по количеству пользователей",
      description:
        "Специальный пакет, предназначенный для крупных компаний, переходящих к цифровым технологиям, позволяющий разрабатывать каждую строку кода на любом языке.",
      button: "Получить информацию",
      link: "https://chatbot.mn/webform/dynamic/5538?__btn__=357219&psid=4090596247724602&__bid__=4",
    },
  ],
  priceTitle2: "Сравнение с аналогичными компаниями",
  tableHd: [
    "НАШИ ВОЗМОЖНОСТИ И ПРЕИМУЩЕСТВА",
    "Chatbot.mn",
    "Manychat",
    "Chatfuel",
  ],
  tableDt: [
    ["Настройка бота командой разработчиков", "+", "-", "-"],
    [
      "Консалтинговые услуги, нацеленные только на вашу компанию",
      "+",
      "-",
      "-",
    ],
    ["Назначенный менеджер", "+", "-", "-"],
    ["Назначенный менеджер работает 24/7", "+", "-", "-"],
    ["Меню магазина 2.0", "+", "-", "-"],
    ["Услуги доставки", "+", "-", "-"],
    ["Онлайн контракт", "+", "-", "-"],
    ["Внедренная система", "+", "-", "-"],
    ["Массовые сообщения", "+", "+", "+"],
    ["Внутренние платежные возможности", "+", "-", "-"],
    ["Руководства и видео уроки", "+", "+", "+"],
    ["Группа разработчиков ботов", "+", "+", "+"],
  ],
  priceCTA:
    "Свяжитесь с нашим менеджером по продажам и получите консультацию, подходящую для вашей отрасли и компании, и выберите нужный вам пакет.",
  priceComment:
    "Отзывы лидеров отрасли, успешно внедривших и использовавших услуги CHATBOT в своей деятельности",
  priceCommentList: [
    {
      id: 0,
      img: emch,
      description:
        "Symptom checker, который мы больше всего ценим, собирает симптомы людей и делает диагноз, указывая, к какому врачу нужно обратиться, и может распространять информацию о больнице разными способами, что показывает, что сервис чат-бота - это не просто автоматический ответчик.",
    },
    {
      id: 1,
      img: pn,
      description:
        "Клиенты требуют информацию в любое время суток, и если мы не отвечаем, вероятность продажи снижается, клиенты становятся недовольными, а имя бизнеса теряет свою репутацию. Однако мы смогли решить эту проблему с помощью чат-бота.",
    },
    {
      id: 2,
      img: itzone,
      description:
        "Группа людей за чат-ботом, в зависимости от особенностей нашей компании и клиентов, быстро делает необходимые настройки и разработки в соответствии с технологией и цифровым окружением, которые развиваются каждый день, что является нашим преимуществом.",
    },
    {
      id: 3,
      img: euro,
      description:
        "Не только мессенджер, но и комментарии к постам можно активировать и предоставить информацию клиентам, а также возможность регистрации на онлайн-туры, а также предоставление нужной информации клиентам в любое время и в любом месте, поэтому мы любим называть чат-бот нашим работником, работающим 24/7.",
    },
  ],
  faqHeader: "ЧАСТО ЗАДАВАЕМЫЕ ВОПРОСЫ",
  faq: [
    {
      id: 0,
      title: "Как создать бота?",
      description:
        "Если вы впервые заходите на chatbot.mn, вам нужно зарегистрироваться, если вы возвращаетесь, вам нужно войти в систему. Один пользователь может создать до 5 ботов бесплатно. Вы можете использовать пакет PRO на протяжении 1 месяца, после чего вы автоматически перейдете на бесплатный пакет.",
    },
    {
      id: 1,
      title: "Как подключить бота?",
      description:
        "Человек, обладающий правами администратора страницы Facebook, имеет право подключить и отключить чат-бота. После нажатия на кнопку «Подключить» => перейдите в «EDIT SETTINGS» => выберите страницу => установите все параметры на «YES» и нажмите «DONE».",
    },
    {
      id: 2,
      title: "Что делать, если возникает ошибка «Token expired»?",
      description: `Ошибка «Token expired» возникает при ИЗМЕНЕНИИ ПРАВ АДМИНИСТРАТОРА СТРАНИЦЫ FACEBOOK, ПАРОЛЯ, АДМИНИСТРАТОРА. Поэтому вам нужно: \n1. Войти на панель управления \n2. Перейти в настройки \n3. Нажмите кнопку «Очистить» \n4. Нажмите кнопку «Подключить Facebook» \n5. Выберите свою страницу и подключитесь,`,
    },
    {
      id: 3,
      title: "Как подписаться? Что произойдет, если я не подпишусь?",
      description:
        "Нажмите на пакет в верхнем правом углу панели управления, и вы увидите возможность подписаться на бота и дополнительные услуги бота. После выбора опции вы можете получить счет или оплатить прямо с помощью QR-кода, и бот будет подписан.",
    },
  ],
  productHeader: "ДОПОЛНИТЕЛЬНЫЕ ФУНКЦИИ",
  mainFeature: {
    type: "Основной сервис",
    description:
      "Чатбот ХХК предлагает услуги по разработке и поддержке ботов, с возможностью интеграции с внутренними системами и настройкой в соответствии с особенностями бизнеса клиента.",
  },
  subFeature: "Основные возможности",
  subDesc: [
    "Создание меню на основе потока информации",
    "Добавление ответов на стандартные вопросы",
    "Предоставление информации о товарах и услугах",
    "Сегментация пользователей по нескольким группам",
    "Интеграция с Hubspot",
    "Менеджер, отвечающий только на этот бот",
    "Просмотр аналитики, мониторинг",
    "Проведение платежей",
    "Отправка массовых сообщений",
    "Проведение опросов",
    "Заполнение форм вопросов",
    "Отправка заказных сообщений и др.",
  ],
  featureCTA:
    "МЫ БЛИЗКИ С НАШИМИ КЛИЕНТАМИ И КЛИЕНТАМИ С РЕГУЛЯРНЫМИ ВОЗВРАТНЫМИ СОЕДИНЕНИЯМИ.",
  featureCTA2: [
    {
      title: "Тренинг по улучшению квалификации",
      description:
        "Мы составили график обучения, учитывая специфику работы клиента и его возможности. Есть возможность выбора занятий в 10:00 или 14:00 по будням. <br/>/длительность тренинга составляет 1:30 минуты/",
    },
    {
      title: "Служба поддержки клиентов",
      description:
        "Мы всегда готовы помочь вам с вопросами, связанными с чат-ботом, корректировками, добавлением дополнительной информации, изменениями.",
    },
  ],
  productHeader2: "Преимущества",
  productGrid: [
    {
      id: 0,
      img: grid1,
      title: "Тренинг по улучшению квалификации",
      description:
        "Возможность передавать любую информацию пользователю в виде блока или автоматического ответа, исходя из потока информации.",
    },
    {
      id: 1,
      img: grid2,
      title: "Маркетинг, Способ продаж",
      description:
        "Возможность привлекать и перенаправлять клиентов, используя чат-бот, одну из наиболее эффективных маркетинговых техник.",
    },
    {
      id: 2,
      img: grid3,
      title: "Онлайн-система заказов",
      description:
        "Клиенты могут получить информацию о продуктах и услугах через бота, а также совершать покупки различных товаров, а также получать услуги по бронированию времени и заказу столиков.",
    },
    {
      id: 3,
      img: grid4,
      title: "Обучение информационного канала",
      description:
        "Вы можете отправлять информацию о краткосрочных и долгосрочных акциях и активациях вашим активным пользователям бота через массовую рассылку.",
    },
    {
      id: 4,
      img: grid5,
      title: "Сегментация клиентов",
      description:
        "Вы можете разделить пользователей на несколько групп в соответствии с их статусом, предпочитаемым продуктом, поведением и эффективно направить ваши будущие маркетинговые активности, продажи и акции.",
    },
    {
      id: 5,
      img: grid6,
      title: "Решение платежей",
      description:
        "Система связана с внутренними и внешними платежными системами, поэтому вы можете оплачивать свои товары онлайн и непрерывно просматривать список всех заказов с одного экрана.",
    },
    {
      id: 6,
      img: grid7,
      title: "Отчетность и анализ данных",
      description:
        "Чат-бот, созданный в системе, может просматривать общее количество обращений пользователей по графику и таблице, а также просматривать и анализировать данные за текущий и предыдущий периоды в форме отчета.",
    },
    {
      id: 7,
      img: grid8,
      title: "Отзывы и жалобы пользователей",
      description:
        "Круглосуточное прием и обработка отзывов и жалоб от пользователей, немедленная доставка необходимой и актуальной информации, поддержка работы службы поддержки клиентов, улучшение качества и эффективности продукции и услуг.",
    },
  ],
  button: {
    signin: "Вход",
    signup: "Регистрация",
    download: "Скачать презентацию",
    free: "Попробовать бесплатно",
    priceCTA: "Оставить заявку на консультацию",
    dashboard: "Панель управления",
    logout: "Выход",
  },
  termTitle: "Условия использования",
  termList: [
    {
      header: "Общие условия",
      desc: [
        {
          d: 'Система автоматического ответа на вопросы клиента chatbot.mn - это официальная услуга компании "Чатбот", и цель этого пользовательского соглашения - регулировать права и обязанности, возникающие при использовании этой системы пользователем.',
          d1: [],
        },
        {
          d: "Это соглашение вступает в силу с момента создания учетной записи или аккаунта пользователем в системе и действует до момента его прекращения.",
          d1: [],
        },
      ],
    },
    {
      header: "Важная информация для пользователя",
      desc: [
        {
          d: "Система chatbot.mn может работать, связываясь с мировыми платформами мессенджеров, такими как Facebook Messenger, WeChat, Whatsapp, Telegram, Viber, Line и т.д., но на данный момент она связана только с платформой Facebook Messenger.",
          d1: [],
        },
        {
          d: 'Информация и диалог, передаваемые с помощью бота, созданного пользователем в системе, являются информацией только между данным пользователем и пользователем мессенджера, и компания "Чатбот" не несет никакой юридической ответственности за это.',
          d1: [],
        },
        {
          d: 'Компания "Чатбот" полностью отвечает за конфиденциальность информации об учетной записи пользователя, созданной в системе chatbot.mn, информации о боте и диалогах пользователя мессенджера и не передает ее третьим лицам, если это не предусмотрено законом.',
          d1: [],
        },
        {
          d: "Пользователь может создать одного и более ботов на одной учетной записи, и один бот может быть связан только с одной страницей Facebook.",
          d1: [],
        },
        {
          d: "Пользователь может связать бот, созданный в системе, с страницей Facebook только с правами администратора, которые он контролирует.",
          d1: [],
        },
        {
          d: "При общении пользователя с пользователем мессенджера через бота, созданного в системе, запрещены следующие действия. Включая",
          d1: [
            "Представлять и отправлять информацию, угрожающую, досаждая, клевещущую, нарушающую права других лиц, копированную, нарушающую интеллектуальные права",
            "Распространять информацию, связанную с конфиденциальностью любой организации и частного лица без разрешения",
            "Без разрешения запрашивать у пользователей мессенджера частную и личную финансовую информацию",
            "Совершать любые действия, запрещенные законодательством Монголии",
            "Запрещено использование в избирательных целях.",
          ],
        },
        {
          d: "Бот, созданный пользователем в системе Chatbot.mn, работает путем обмена информацией с подключенной платформой Facebook Messenger, поэтому пользователь обязан полностью соответствовать условиям использования и правилам конфиденциальности платформы Facebook Messenger. Если пользователь совершает действия, не соответствующие условиям использования платформы Facebook Messenger, будут приняты меры по прекращению работы бота пользователя.",
          d1: [],
        },
        {
          d: "Пользователь несет ответственность за защиту логина и пароля от доступа третьих лиц, а также за предотвращение любых рисков, которые могут возникнуть из-за неисполнения этой обязанности. ООО «Чатбот» не несет ответственности за убытки, возникшие в результате этого.",
          d1: [],
        },
        {
          d: "Если пользователь считает, что его пароль от учетной записи был раскрыт третьим лицам, пожалуйста, смените пароль или срочно свяжитесь с нами по адресу info@chatbot.mn или по телефону 7744-1616.",
          d1: [],
        },
      ],
    },
    {
      header: "Оплата за услуги, отмена",
      desc: [
        {
          d: "Система Chatbot.mn предлагает пользователям варианты бесплатного использования, а также возможности платных услуг.",
          d1: [],
        },
        {
          d: "Для использования платной функции пользователь должен активировать платную услугу на боте, а затем заключить договор на платную услугу.",
          d1: [],
        },
        {
          d: "Тариф на платную услугу открыто доступен на веб-сайте Chatbot.mn, и цена может варьироваться в зависимости от количества пользователей в текущем месяце и платной услуги.",
          d1: [],
        },
        {
          d: "Пользователь имеет возможность активировать платную услугу для каждого бота, созданного пользователем, и плата за услугу рассчитывается для каждого бота.",
          d1: [],
        },
        {
          d: "Расчет платы за услугу за текущий месяц автоматически производится 1-го числа следующего месяца и отправляется на зарегистрированную электронную почту пользователя, а плата должна быть оплачена до 20-го числа следующего месяца. Если плата не будет оплачена до 20-го числа следующего месяца, пользователь не сможет использовать возможности платной услуги.",
          d1: [],
        },
        {
          d: "Пользователь имеет возможность отменить платную услугу в любое время, и в этом случае будет отправлен расчет платы за услугу до дня отмены услуги пользователю.",
          d1: [],
        },
        {
          d: "Если платная услуга отменена, платные возможности будут деактивированы, и бесплатные возможности могут быть использованы непрерывно.",
          d1: [],
        },
        {
          d: "Клиент автоматически перейдет на Бесплатный пакет по истечении срока действия платного пакета, а меню сервиса чат-бота при переходе будет ограничено. Рекламные объявления также будут периодически отправляться во время доступа пользователя к Службе чат-бота.",
          d1: [],
        },
      ],
    },
    {
      header:
        "Отключение Messenger, удаление Chatbot и закрытие учетной записи",
      desc: [
        {
          d: "Пользователь имеет возможность отключать или подключать бота к странице Facebook в любое время, и при отключении будет удалена история переписки и информация о пользователе мессенджера, связанная с этим мессенджером Facebook. После отключения бота от страницы Facebook можно подключить новую страницу Facebook.",
          d1: [],
        },
        {
          d: "Пользователь имеет возможность удалить созданного им бота, и в этом случае будет удалена вся информация, связанная с ботом, включая блок сообщений, диалоги, данные пользователей и т. д.",
          d1: [],
        },
        {
          d: "Если на боте, созданном пользователем, есть неоплаченный счет, бот не может быть удален.",
          d1: [],
        },
        {
          d: "Пользователь имеет возможность закрыть свою учетную запись в системе Chatbot.mn, и в этом случае будут удалены все боты и вся информация, связанная с ботом.",
          d1: [],
        },
        {
          d: "Если на учетной записи пользователя есть неоплаченный счет, учетная запись не может быть закрыта.",
          d1: [],
        },
      ],
    },
    {
      header: "Прочее",
      desc: [
        {
          d: "Это условие обслуживания может быть дополнительно изменено в зависимости от обновления работы ООО «Чатбот», и при наличии дополнительных изменений все пользователи, использующие услугу, будут предварительно уведомлены.",
          d1: [],
        },
        {
          d: 'Платформа создания интеллектуального чат-бота chatbot.mn (далее "Система") зарегистрирована под названием ООО "Чатбот" в Комиссии по регулированию связи, и интеллектуальная собственность веб-сайта и системы Chatbot.mn защищена законодательно.',
          d1: [],
        },
      ],
    },
  ],
  privacyTitle: "Политика конфиденциальности",
  privacyList: [
    {
      header: "Общее и сбор данных",
      desc: [
        {
          d: 'Система создания интеллектуального чат-бота chatbot.mn (далее "Система") собирает информацию граждан, юридических лиц /далее "Пользователь"/ и пользователей их страниц Facebook /далее "Пользователь чатбота"/ в следующих случаях. Включая:',
          d1: [
            {
              d2: "Под 'Пользователем' понимается физическое или юридическое лицо, которое входит в веб-систему chatbot.mn, создает регистрацию, подключает административную или управленческую страницу Facebook и предоставляет автоматическую услугу ответа чатбота пользователям чатбота.",
              d3: [],
            },
            {
              d2: "Под 'Пользователем чатбота' понимаются конечные пользователи, которые связаны с автоматическим ответным чатботом после нажатия кнопки «Отправить сообщение» и других кнопок «Facebook Chat Plugin» на подключенной странице Facebook Пользователя.",
              d3: [],
            },
            {
              d2: "При регистрации в Системе Пользователь вводит свою электронную почту, фамилию, имя, номер телефона и пароль, и система хранит созданный пользователем пароль в зашифрованном виде в базе данных, нечитаемом без вмешательства человека.",
              d3: [],
            },
            {
              d2: "Также Пользователь должен войти через учетную запись Facebook для подключения своей страницы Facebook, в этот момент система получает фамилию, имя, электронную почту, дату рождения, пол, открытую информацию настроек и необходимые для подключения страницы Facebook идентификатор приложения Facebook, токен доступа и хранит их.",
              d3: [],
            },
            {
              d2: "Как только Пользователь чатбота начинает общение с автоматическим ответным чатботом, созданным Пользователем, они регистрируются в системе, и их фамилия, имя, электронная почта, телефон, дата рождения, пол и другая личная информация, а также «Facebook PSID» (Page Scoped ID) сохраняются в системе.",
              d3: [],
            },
            {
              d2: "Если Пользователь использует функции калькулятора оплаты и интернет-магазина Системы, ему необходимо ввести и сохранить в системе свидетельство о регистрации организации, копию удостоверения личности гражданина и информацию о счете.",
              d3: [],
            },
          ],
        },
      ],
    },
    {
      header: "Использование информации",
      desc: [
        {
          d: "Вся информация, введенная в систему пользователем и пользователем чатбота, является собственностью каждого, и ООО «Чатбот» не будет использовать ее в какой-либо форме, кроме оговоренных условий, без согласия Пользователя и Пользователя чатбота.",
          d1: [
            {
              d2: "Для Пользователя:",
              d3: [
                "chatbot.mn будет проверять информацию о плате за «облачную» услугу и заказе, отправлять уведомления, связанные с ними, производить оплату платы за услугу (с согласия Пользователя)",
                "Предоставлять клиентское обслуживание, связанное с Пользователем (с согласия Пользователя)",
                "Выполнять техническое обслуживание, связанное с Пользователем (с согласия Пользователя)",
                "Рассылать информацию о новых продуктах и скидочных акциях Пользователям (с согласия Пользователя)",
                "ООО «Чатбот» имеет возможность собирать и использовать анонимизированную информацию о чатботе пользователя и связанной информации для статистических целей с целью улучшения качества сервиса chatbot.mn и внедрения новых дополнительных функций.",
              ],
            },
            {
              d2: "Для Пользователя чатбота:",
              d3: [
                "Система использует «Facebook PSID» соответствующего пользователя чатбота для отправки сообщений для общения с пользователем чатбота",
                "Пользователь использует информацию своего пользователя чатбота для отправки информации о своем бизнесе, товарах и услугах (через «Рассылку сообщений» или массовую рассылку, одобренную Facebook.)",
                "Пользователь будет использовать систему для предоставления клиентского обслуживания и других услуг своим пользователям чатбота.",
              ],
            },
          ],
        },
      ],
    },
    {
      header: "Раскрытие информации о пользователе",
      desc: [
        {
          d: "",
          d1: [
            {
              d2: "Только в случае необходимости раскрытия информации по требованию государственного органа в соответствии с законодательством Монголии",
              d3: [],
            },
          ],
        },
      ],
    },
    {
      header: "Хранение информации о пользователе",
      desc: [
        {
          d: "",
          d1: [
            {
              d2: "Информация о регистрации пользователя, информация о чатботе, информация о пользователе чатбота, история переписки и все другие информации о пользователе и пользователе чатбота, созданные в системе chatbot.mn, будут храниться на сервере в дата-центре ООО «Мобинет» с высоким уровнем конфиденциальности и безопасности ООО «Чатбот».",
              d3: [],
            },
            {
              d2: "Пользователи несут ответственность за нераскрытие своего логина и пароля третьим лицам.",
              d3: [],
            },
            {
              d2: "ООО «Чатбот» не будет нести ответственности за любые убытки или повреждения, возникшие в результате утраты прав доступа пользователя другими лицами. ООО «Чатбот» предупреждает, что отсутствует возможность доступа к информации с использованием прав доступа пользователя.",
              d3: [],
            },
          ],
        },
      ],
    },
    {
      header: "Управление информацией о пользователе",
      desc: [
        {
          d: "",
          d1: [
            {
              d2: "Пользователь может войти в свою учетную запись в любое время и внести изменения в свои персональные данные и страницу Facebook, связанную с учетной записью.",
              d3: [],
            },
            {
              d2: "Пользователь может удалить все свои данные в системе, закрыв свою учетную запись в системе.",
              d3: [],
            },
            {
              d2: "Если пользователь забыл пароль от своей учетной записи, он может сбросить его, следуя инструкциям на странице входа.",
              d3: [],
            },
          ],
        },
      ],
    },
    {
      header: "Политика использования файлов cookie",
      desc: [
        {
          d: "Система chatbot.mn не использует файлы cookie для отслеживания пользовательской активности и не будет передавать файлы cookie третьим лицам.",
          d1: [],
        },
      ],
    },
    {
      header: "Защита данных",
      desc: [
        {
          d: "",
          d1: [
            {
              d2: "ООО «Чатбот» обязана предотвращать утечку, утрату, повреждение и вмешательство в безопасность данных пользователей.",
              d3: [],
            },
            {
              d2: "ООО «Чатбот» обязана принимать соответствующие меры по защите безопасности данных пользователей от хакерских атак и вмешательства третьих лиц.",
              d3: [],
            },
            {
              d2: "ООО «Чатбот» будет принимать технические и административные меры для обеспечения безопасности данных пользователей.",
              d3: [],
            },
            {
              d2: "ООО «Чатбот» обязана обучать сотрудников, имеющих доступ к данным пользователей, и осуществлять строгий контроль за использованием данных.",
              d3: [],
            },
          ],
        },
      ],
    },
    {
      header: "Согласие пользователя",
      desc: [
        {
          d: "",
          d1: [
            {
              d2: "Пользователь считается прочитавшим и согласившимся с политикой конфиденциальности и условиями использования, когда он регистрируется в Системе chatbot.mn, и Пользователь чатбота считается прочитавшим и согласившимся с политикой конфиденциальности и условиями использования, когда он начинает общаться с ботом пользователя.",
              d3: [],
            },
          ],
        },
      ],
    },
  ],
  //////////////////////////////////////////////////////////////
  heroStat: {
    col1: "Пользователи, обратившиеся к чатботу",
    col2: "Бренды, разработанные и внедренные виртуальным помощником",
    col3: "Предприятия, взаимодействующие с пользователями",
  },
  products: [
    {
      id: 0,
      type: "Онлайн-магазин",
      description:
        "Пользователь может легко найти и купить нужный продукт, выбрав его по категориям и получив подробную информацию о продукте без необходимости покидать веб-сайт.",
    },
    {
      id: 1,
      type: "Комментарии на ответы",
      description:
        "Пользователи, имеющие запросы на получение информации в комментариях под постами на Facebook, могут автоматически получать подробную информацию через мессенджер без задержек и в любое время.",
    },
    {
      id: 2,
      type: "Онлайн-чат",
      description:
        "Возможность общаться с представителями службы поддержки в режиме реального времени, получать информацию о состоянии заказа, условиях и требованиях, а также получать персонализированное обслуживание на основе предпочтений и потребностей пользователя.",
    },
    {
      id: 3,
      type: "Доставка",
      description:
        "После оплаты покупатель выбирает компанию доставки и получает товар по указанному адресу, что является универсальным решением для раздела магазина.",
    },
    {
      id: 4,
      type: "Онлайн-договор",
      description:
        "Пользователь заполняет необходимую информацию через форму запроса, и после автоматической загрузки в черновик договора готового к заполнению договора отправляется электронной почтой обеим сторонам.",
    },
  ],
  productHeader3: "Связь между отделами",
  productSlide: [
    {
      id: 0,
      type: "Маркетинговая группа",
      title:
        "Развивает уникальный маркетинг, привлекает реальных клиентов и создает эффективное взаимодействие.",
      description: [
        "Создает взаимодействие между компанией и клиентами.",
        "Повышает уровень удовлетворенности клиентов",
        "Создает высокую репутацию бренда",
        "Привлекает клиентов, заинтересованных в бренде и с ним связанных",
      ],
    },
    {
      id: 1,
      type: "Группа продаж",
      title:
        "С помощью технологического прогресса обновляет бизнес-модель и осуществляет продажи.",
      description: [
        "Улучшает продажи",
        "Собирает информацию о потенциальных клиентах",
        "Поддерживает деятельность группы продаж",
        "Автоматизирует процесс продаж",
      ],
    },
    {
      id: 2,
      type: "Группа обслуживания",
      title:
        "Обеспечивает возможность повторного использования опыта и перепродажи услуг, полученных от обслуживания.",
      description: [
        "Предоставляет информацию клиентам в любое время суток",
        "Быстро решает вопросы и проблемы через систему отзывов",
        "Увеличивает потенциал группы обслуживания",
        "Увеличивает повторные продажи, привлекая довольных клиентов",
      ],
    },
  ],
  footer: {
    title: "ПОПРОБУЙТЕ ЧАТБОТ БЕСПЛАТНО",
    subtitle: "ИСПОЛЬЗУЙТЕ ЧАТБОТ НА ВАШЕМ ВЕБ-САЙТЕ СЕЙЧАС",
    col1: "Чатбот",
    row1: "О нас",
    row2: "Вопросы и ответы",
    row3: "Группы пользователей чатбота",
    row4: "Связаться с нами",
    col2: "Продукты",
    row5: "Разработка бота",
    row6: "Тренинг и поддержка",
    row7: "Услуги-пакеты",
    row8: "Обслуживание клиентов",
    col3: "Поддержка",
    row9: "Справка",
    row10: "Онлайн-консультация",
    row11: "Отзывы",
    row12: "Шаблоны",
    copyright: "Все права защищены",
    year: "",
  },
  about: {
    title: "О НАС",
    p1: "NSP LLC, основанная монгольскими учеными, занимающимися многолетними исследованиями в области технологий, разрабатывает платформу чат-ботов с 2019 года, начав как стартап и позднее создав Chatbot LLC.",
    p2: "NSP LLC автоматизирует обслуживание клиентов и внедряет технологические инновации в различные типы бизнеса, обеспечивая возможность предоставления услуг более чем 3500 ежедневным клиентам.",
    row1Title: "МИССИЯ",
    row2Title: "ПРЕИМУЩЕСТВА",
    row3Title: "ВИДЕНИЕ",
    row1: "Продвижение интеллектуального потенциала и инноваций монгольских ученых на глобальном уровне.",
    row2: [
      "Командная работа",
      "Продвинутые технологии",
      "Эффективная операция",
    ],
    row3: "Наша цель - развить платформу чат-ботов в глобально признанный продукт и предоставлять услуги на внутренний и международный рынки с различными типами решений.",
  },
  input: {
    email: "Электронная почта",
    pwd: "Пароль",
    lName: "Фамилия",
    fName: "Имя",
    phone: "Телефон",
    forgot: "Забыли пароль?",
    remember: "Запомнить меня",
    signinif: "Уже есть аккаунт? Войти",
    signupif: "Если у вас нет аккаунта", //end darj burgtuulne uu gdgiig ni tusad ni ene hesegt bicheed ogoorei
    signupifLink: "ЗАРЕГИСТРИРОВАТЬСЯ ЗДЕСЬ.", //end darj burgtuulne uu gdgiig ni tusad ni ene hesegt bicheed ogoorei
    acceptTerm: 'Нажимая "ЗАРЕГИСТРИРОВАТЬСЯ", вы соглашаетесь с нашими',
    acceptTerm2: "",
    forgotTitle: "Введите зарегистрированную электронную почту",
    forgotButton: "Сбросить пароль",
    button: "Войти с помощью Facebook",
    privacy: "Политика конфиденциальности.",
    term: "Условия использования",
    label1: "Введите адрес электронной почты",
    label2: "Введите пароль",
    label3: "Введите фамилию",
    label4: "Введите имя",
    label5: "Введите номер телефона",
  },
  tutorial: {
    h2: "Сотрудничество с Байденом",
    h3: "Темы обучения",
    h4: "Возможности чатбота №",
    button: "Подробнее",
    input: "Поиск...",
    templateTitle: "Связанные шаблоны",
    request: "Вы хотите записаться на нашу программу?",
    formName: "Ваше имя",
    formOrg: "Организация",
    formDate: "Дата / Доступно для записи в 10:00 в этот день /",
    formEmail: "Ваш адрес электронной почты",
    formPhone: "Ваш контактный телефон",
    formSubmit: "Отправить запрос",
    phoneLabel: "Номер телефона: ",
    phone: "77441616",
    addressLabel: "Наш адрес: ",
    address:
      "Улан-Батор, Монголия, Сухбаатарский район, ул. Энхтайвны өргөн чөлөө, 15A/5, Ambassador office, 1 этаж 104",
    paragraph:
      "Вы получите подробную информацию о сервисе чатбота, используемого в маркетинге для общения с клиентами, а также узнаете важную информацию и возможности, как успешно использовать важные данные от опытных разработчиков ботов.",
    cards: [
      {
        id: 1,
        title: "Загрузка информации",
        url: "https://www.youtube.com/embed/GBHuScg_5Mc?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Вы можете отправлять всевозможную информацию о ваших продуктах и услугах пользователям в различных форматах, таких как изображения, текст, видео, файлы и ссылки, и планировать их доставку пользователям в любое время в течение 7 дней.",
      },
      {
        id: 2,
        title: "Интерактивный чат",
        url: "https://www.youtube.com/embed/YhdgfHWzPAU?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Вы можете отвечать на вопросы, поступающие от пользователей, подходящими ответами и отправлять подробную информацию в ответ на эти вопросы.",
      },
      {
        id: 3,
        title: "Интерактивный вопрос-ответ",
        url: "https://www.youtube.com/embed/YxUOWzF2FuI?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Автоматически создавайте и отправляйте интерактивные вопросы и их ответы пользователям на основе поведения пользователей и обеспечивайте быструю и простую доставку информации.",
      },
      {
        id: 4,
        title: "Оператор в реальном времени",
        url: "https://www.youtube.com/embed/qAoZvdaLWJw?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Пользователи могут общаться с оператором в реальном времени через чатбота, и чатбот может предоставлять подробную информацию, подходящую именно для их потребностей. Кроме того, бизнесы могут оценивать операторов на основе оценок пользователей (от 1 до 5) и улучшать качество обслуживания, отслеживая ответы.",
      },
      {
        id: 5,
        title: "Анкетная форма",
        url: "https://www.youtube.com/embed/OAgFNUG1NTg?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Создавайте анкетные формы через чатбота и собирайте обратную связь пользователей, различные опросы и информацию в формате чата.",
      },
      {
        id: 6,
        title: "Веб-форма",
        url: "https://www.youtube.com/embed/Hth3BvrSgyo?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Вставьте дополнительные окна в чат и собирайте данные от пользователей. Когда веб-форма заполняется, можно получить ответы по электронной почте или загрузить их в виде файла Excel.",
      },
      {
        id: 7,
        title: "Массовая рассылка",
        url: "https://www.youtube.com/embed/YTgT3gQAFIA?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Отправляйте стандартные сообщения, рекламу и подробную информацию всем пользователям, которые взаимодействовали с вашим чатботом, или сегментированным группам пользователей в течение 24 часов или в указанные периоды времени.",
      },
      {
        id: 8,
        title: "Ответ на комментарий",
        url: "https://www.youtube.com/embed/yaNh9Nteaqk?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "На странице Facebook, когда пользователи оставляют комментарии под постами, чатбот может автоматически отвечать на комментарии и предоставлять два типа ответов: 1) Отправлять предустановленный ответ на основе ключевых слов в комментарии пользователя, и 2) Устанавливать настраиваемый ответ на любой комментарий независимо от его содержания. Также можно удалять комментарии от пользователей, несоответствующие определенным ключевым словам, сохраненным в чатботе.",
      },
      {
        id: 9,
        title: "Данные пользователя",
        url: "https://www.youtube.com/embed/eENdtu7CSis?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Данные, связанные с пользователями, клиентами, историей чатов, опросами, веб-формами и т. д., могут храниться в панели управления чатбота.",
      },
      {
        id: 10,
        title: "Теги пользователей",
        url: "https://www.youtube.com/embed/e1dg50_w9GE?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Категоризируйте пользователей чатбота на основе их статуса, действий и помечайте их соответствующим образом. Также можно создавать пользовательские группы и управлять конкретной информацией, относящейся к этим группам.",
      },
      {
        id: 11,
        title: "Статические страницы",
        url: "https://www.youtube.com/embed/57HuwrdPk4o?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Создайте дополнительные окна со статической информацией внутри мессенджера.",
      },
      {
        id: 12,
        title: "Интеграция с системой",
        url: "https://www.youtube.com/embed/FXZgnKigRkQ?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Интеграция с внутренними системами, такими как Google Sheets, Google Calendar, Hubspot и т. д., возможна для бизнес-операций.",
      },
      {
        id: 13,
        title: "Аналитика",
        url: "https://www.youtube.com/embed/Ez1eO5ce6E4?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Просматривайте статистику пользователей и данные о поведении, такие как продажи и действия, ежедневно, ежемесячно или по выбранной дате, и загружайте их в виде файлов Excel.",
      },
      {
        id: 14,
        title: "Монитор",
        url: "https://www.youtube.com/embed/qfWTKhNH660?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Раздел Монитор отображает информацию, такую как ошибки бота, неотвеченные запросы пользователей и действия пользователей в системе ежедневно, ежемесячно и на выбранную дату.",
      },
      {
        id: 15,
        title: "Магазин 2.0",
        url: "https://www.youtube.com/embed/-7VLTCTJeg0?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Магазин 2.0 - это ваш интернет-магазин, который позволяет клиентам просматривать и покупать продукты, не покидая окно переписки. Он интегрирован с внутренними платежными системами, такими как QPay и SocialPay, что обеспечивает безупречные и удобные платежные транзакции. \nС помощью Магазина 2.0 вы можете: \nДобавлять продукты в магазин. \nУстанавливать скидки. \nДобавлять информацию о клиентах. \nКатегоризировать продукты. \nСоздавать специальные предложения. \nОтображать баннеры. \nСоздавать коллекции. \nУстанавливать таймеры обратного отсчета. \nУправлять запасами и остатками. \nПозволять клиентам добавлять выбранные продукты в корзину. \nНастроить адреса доставки. \nАктивировать онлайн-обработку платежей за покупку товаров.",
      },
      {
        id: 16,
        title: "Интеграция платежей",
        url: "https://www.youtube.com/embed/FpRjP7BcEJU?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Чатбот не только автоматически отвечает и обрабатывает коммуникацию между организациями и пользователями. Он также способен интегрироваться с внутренними платежными системами для расчета платежей за различные продукты и услуги.",
      },
      {
        id: 17,
        title: "Доставка",
        url: "https://www.youtube.com/embed/ucvgziyd5uM?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Чатбот эффективно управляет разделом Онлайн-магазин 2.0, интегрируясь с системами компаний по доставке, что обеспечивает автоматическую и удобную доставку продуктов для пользователей.",
      },
      {
        id: 18,
        title: "Заказ",
        url: "https://www.youtube.com/embed/JInmNgDLLwA?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Раздел Заказы позволяет пользователям размещать заказы и производить платежи с помощью QPAY и Social Pay. Также он обеспечивает расчет платежей за различные продукты и услуги, не ограничиваясь только покупкой продуктов, но также регистрацией и оплатой, бронированием билетов и подтверждением регистраций.",
      },
      {
        id: 19,
        title: "Создание бота, подключение страниц",
        url: "https://www.youtube.com/embed/rGJCF4wNNR4?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "В этом руководстве вы узнаете, как создать нового бота, просматривать все созданные боты, искать по названию и управлять шаблонами своего бота, представляя различные модели ботов, подходящие для ваших операций, и позволяя вашему собственному боту взаимодействовать с зарегистрированными пользователями в системе чатботов, предоставляя разрешения и ассоциируя данные с ними.",
      },
      {
        id: 20,
        title: "Блок сообщений",
        url: "https://www.youtube.com/embed/kR57nSi3lOw?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Раздел Блок сообщений позволяет пользователям упорядочить информацию, которую необходимо доставить пользователям аккуратно, тестировать последовательность операций, объединять их, представляя основное поле для создания совершенного бота.",
      },
      {
        id: 21,
        title: "Настройки: Общие",
        url: "https://www.youtube.com/embed/r1Dq405xpKo?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Страница Общие настройки состоит из 5 разделов: введение, первое сообщение, авторизованные домены, персона и настройки бота, позволяя пользователям настраивать первое взаимодействие с их пользователями, определять зарегистрированные домены на их страницах и выбирать аватар или обложку бота.",
      },
      {
        id: 22,
        title: "Настройки: Приветствия",
        url: "https://www.youtube.com/embed/xIn51RFCXXc?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Раздел Приветствия позволяет настраивать приветствия на праздничные дни, ежедневные приветствия, приветствия для определенных взаимодействий и прощальные сообщения.",
      },
      {
        id: 23,
        title: "Настройки: Аутентификация пользователя",
        url: "https://www.youtube.com/embed/FQqOZJ8l_XY?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Раздел 'Аутентификация пользователя' позволяет пользователям, которые впервые обращаются к вашему чатботу организации, предоставить необходимую информацию и задать вопросы в самом начале, такие как регистрация своего номера телефона и адреса электронной почты, позволяя компаниям использовать ее для сбора данных.",
      },
      {
        id: 24,
        title: "Настройки: Основной раздел",
        url: "https://www.youtube.com/embed/0nXYYo1kpig?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Основной раздел - это самый важный раздел, в котором пользователи могут разместить информацию, которую они хотят показать пользователям в правом нижнем углу окна мессенджера, обеспечивая отличные результаты с этой информацией.",
      },
      {
        id: 25,
        title: "Пользователи",
        url: "https://www.youtube.com/embed/xTIwo1l3A-g?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "В разделе Пользователи вы можете увидеть список всех пользователей, зарегистрировавшихся в чат-боте вашей организации, нажав на кнопку Начать, и просмотреть подробную информацию о каждой их истории чатов, а также отправлять им сообщения.",
      },
      {
        id: 26,
        title: "Атрибуты пользователей",
        url: "https://www.youtube.com/embed/vefrnuDnGyY?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Атрибуты относятся к характеристикам, присущим пользователям, и организации используют их для хранения желаемой информации, полученной от пользователей, что позволяет сегментировать пользователей на основе этой информации и эффективно проводить маркетинговые кампании, например, отправлять массовые сообщения, используя эти данные.",
      },
      {
        id: 27,
        title: "Сегменты пользователей",
        url: "https://www.youtube.com/embed/vWOzDqq3cPQ?list=PLGsALiW67iaaJv4rrKkG7cvKLZfL5BmrE",
        description:
          "Сегментация зарегистрированных пользователей с помощью тегов и атрибутов и отправка им массовых сообщений.",
      },
      {
        id: 28,
        title: "Подтверждение заказа",
        url: "",
        description:
          "Сообщения подтверждения заказа используются для информирования пользователей об информации, настроенной и отправленной при неправильно выполненных действиях для исправления, успешных продаж или повторного подтверждения доставки продукта и услуги.",
      },
      {
        id: 29,
        title: "JSON API",
        url: "",
        description:
          "При подключении пользователей через API для получения информации используются данные, такие как информация о полях, графике времени, информация о продуктах, погода на определенную дату и выбранная валютная ставка.",
      },

      {
        id: 30,
        title: "Контент",
        url: "",
        description:
          "Раздел Контент организует блоки с полезной информацией, к которой можно получить доступ с помощью различных быстрых команд, что позволяет пользователям быстро получать упорядоченную информацию и предоставлять контент пользователям, которым она наиболее нужна.",
      },
    ],
    templates: [
      {
        id: 1,
        title: "Онлайн худалдаа",
        img: ecommerce,
        button: "Просмотреть шаблон",
        desc: "Позволяет продавать продукты и услуги онлайн",
        list: [
          "Отображение категорий продуктов",
          "Отображение типов продуктов",
          "Предоставление подробной информации о продуктах",
          "Предложение скидочных цен на продукты",
          "Управление запасами продукции и продажами",
          "Обработка размещения заказов, доставки и оплаты",
          "Установка акций и скидок",
          "Отслеживание уровней запасов на складе",
        ],
      },
      {
        id: 2,
        title: "Здравоохранение",
        img: hospital,
        button: "Просмотреть шаблон",
        desc: "Помогает заботиться о здоровье пользователей",
        list: [
          "Предоставление медицинских советов",
          "Консультации медицинских специалистов",
          "Ответы на вопросы пользователей",
          "Рекомендация конкретных продуктов и услуг",
          "Планирование встреч и приемов",
          "Проведение опросов и оценок состояния здоровья",
          "Отображение рабочего расписания",
          "Предоставление информации о местоположении",
        ],
      },
      {
        id: 3,
        title: "Рестораны и Зоопарки",
        img: restaurant,
        button: "Просмотреть шаблон",
        desc: "Для ресторанов, зоопарков, кафе, баров, пабов, караоке и т. д.",
        list: [
          "Представление меню",
          "Получение обратной связи и предложений",
          "Принятие заказов на еду",
          "Принятие заказов на напитки",
          "Управление рабочим временем",
          "Предоставление информации о местоположении",
          "Предоставление специальных акций",
          "Ответы на запросы клиентов",
        ],
      },
      {
        id: 4,
        title: "Недвижимость",
        img: realestate,
        button: "Просмотреть шаблон",
        desc: "Организация различных деталей недвижимости",
        list: [
          "Запись на встречу с агентами",
          "Предоставление советов и информации о недвижимости",
          "Предоставление объявлений о продаже недвижимости",
          "Сдача в аренду и продажа деталей недвижимости",
          "Регистрация объявлений о продаже недвижимости",
          "Выделение рекламных объявлений",
          "Классификация и фильтрация объявлений",
          "Сбор информации о клиентах",
        ],
      },
      {
        id: 5,
        title: "Образование",
        img: education,
        button: "Просмотреть шаблон",
        desc: "Автоматизация предоставления информации и регистрации студентов",
        list: [
          "Ответы на часто задаваемые вопросы",
          "Рассылка необходимых новостей и информации",
          "Представление программ и правил приема",
          "Автоматизация регистрации студентов",
          "Распределение выбора курсов и расписаний",
          "Предоставление информации о факультетах и кафедрах",
          "Рассылка информации новым поступающим",
          "Сбор и обработка обратной связи",
        ],
      },
    ],
    notFound: "Страница не найдена",
  },
};
